import { createSelector } from '@reduxjs/toolkit';
import { IVendorSlice } from 'store/slices/vendor';
import { ISelector } from 'store/types';
import { TIntegrationType } from 'types/api';
import { Nullable } from 'types/common';

export const getVendorSlice: ISelector<void, IVendorSlice> = (state) => state.vendor;

export const getVendorIntegrationType = createSelector(
	[getVendorSlice],
	(vendorSliceState): Nullable<TIntegrationType> => {
		return vendorSliceState.data?.info.integration_type ?? 'platform';
	}
);
