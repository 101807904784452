import { FC, PropsWithChildren } from 'react';
import { Card, CardProps } from 'antd';

export const CustomCard: FC<CardProps & PropsWithChildren> = ({ children, ...props }) => {
	const newStyles = { border: 0, borderRadius: 16, ...props.style };
	const headStyles = { border: 'none', padding: '24px 24px 0 24px' };
	return (
		<Card
			style={newStyles}
			headStyle={headStyles}
			{...props}
		>
			{children}
		</Card>
	);
};
