import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import branchSlice from './slices/branch';
import countriesSlice from './slices/countries';
import dispatcherSlice from './slices/dispatcher';
import integrationsSlice from './slices/integrations';
import orderSlice from './slices/order';
import settingsSlice from './slices/settings';
import vendorSlice from './slices/vendor';

const combinedReducer = combineReducers({
	countries: countriesSlice,
	settings: settingsSlice,
	vendor: vendorSlice,
	branch: branchSlice,
	order: orderSlice,
	dispatcher: dispatcherSlice,
	integrations: integrationsSlice,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
	if (action.type === 'userSlice/logout') state = undefined;
	return combinedReducer(state, action);
};

export default rootReducer;
