import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForceRefreshData } from 'hooks';
import { TEmptyFunction } from 'types/common';
import { useIntervalTimer } from './useIntervalTimer';

export interface IWithRefreshTimer {
	isEnable: boolean;
	toggleRefreshEnable: Dispatch<SetStateAction<boolean>>;
	onRefresh: TEmptyFunction;
	refreshId: number; // incremented whenever a force refresh is required
}

export const useRefreshTimer = (ttl: number, isEnableProp: boolean = true): IWithRefreshTimer => {
	const { isEnable, intervalUpdate, toggleRefreshEnable } = useIntervalTimer(ttl);

	const { forceRefreshData, refreshingData } = useForceRefreshData();

	useEffect(() => {
		refreshingData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intervalUpdate]);

	return {
		isEnable,
		toggleRefreshEnable,
		onRefresh: refreshingData,
		refreshId: forceRefreshData, // incremented whenever a force refresh is required
	};
};
