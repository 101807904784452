import { FC, useEffect, useState } from 'react';
import { ECampaignFormFields } from 'types/api/promotions';
import { ITimeRangeSliderProps } from './types';
import { TranslatedLabel } from 'components/TranslatedLabel/TranslatedLabel';
import { NEW_TIME_RANGE_MARKS, NEW_TIME_RANGE_MARKS_LENGTH } from './TimeRangeSlider.configs';
import styles from './TimeRangeSlider.module.css';
import { ConfigProvider, Form, Input, Slider, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';

const TimeRangeSlider: FC<ITimeRangeSliderProps> = ({ form, disabled }) => {
	const [sliderValue, setSliderValue] = useState<number>(0);

	// ! useWatches
	const time_range = useWatch(ECampaignFormFields.TIME_RANGE, form);

	// ! handlers
	const handleSliderChange = (value: number) => {
		form.setFieldValue(ECampaignFormFields.TIME_RANGE, NEW_TIME_RANGE_MARKS[value].value);
	};

	// ! useEffects
	useEffect(() => {
		if (!time_range) return;
		const timeRangeIndex = Object.values(NEW_TIME_RANGE_MARKS).findIndex((v) => v.value === time_range);

		form.setFieldValue(
			ECampaignFormFields.TIME_RANGE,
			NEW_TIME_RANGE_MARKS[timeRangeIndex === -1 ? 0 : timeRangeIndex].value
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [time_range]);

	useEffect(() => {
		if (!time_range) return;

		const currentSelectedSegment = Object.entries(NEW_TIME_RANGE_MARKS).find(
			([, value]) => value.value === time_range
		);

		setSliderValue(currentSelectedSegment ? +currentSelectedSegment[0] : 0);
	}, [time_range]);

	// ! render
	return (
		<>
			<Form.Item
				name={ECampaignFormFields.TIME_RANGE}
				noStyle
				hidden
			>
				<Input />
			</Form.Item>

			<Space
				direction='vertical'
				className='w-100'
			>
				<TranslatedLabel
					i18nKey='create.form.time_range.sub.title'
					nameSpace='campaigns'
				/>
				<ConfigProvider
					theme={{
						components: {
							Slider: {
								controlSize: 8,
								railSize: 8,
								handleSize: 20,
								handleLineWidth: 2,
								handleLineWidthHover: 4,
							},
						},
					}}
				>
					<Slider
						disabled={disabled}
						min={0}
						max={NEW_TIME_RANGE_MARKS_LENGTH}
						value={sliderValue}
						className={styles.custom_slider}
						tooltip={{
							formatter: (value?: number) => {
								if (value === undefined) return '';

								return NEW_TIME_RANGE_MARKS[value].label;
							},
						}}
						onChange={handleSliderChange}
					/>
				</ConfigProvider>
				{sliderValue !== undefined && (
					<span>
						<Space size='small'>
							<Typography.Title
								level={4}
								style={{ margin: 0 }}
							>
								{NEW_TIME_RANGE_MARKS[sliderValue].label}
							</Typography.Title>
						</Space>
					</span>
				)}
			</Space>
		</>
	);
};

export default TimeRangeSlider;
