import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from 'configs/common';
import { ESupportedCountryCodes } from 'types/common';
import { Button, Dropdown, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

export const PrintLanguageChoiceModal: FC<{
	onPrint: (language: ESupportedCountryCodes) => Promise<void>;
}> = ({ onPrint }) => {
	const { t: tCommon } = useTranslation('common');
	const { t: tPrintOrder } = useTranslation('print-order');

	const languageVariantButtons = Object.values(SUPPORTED_LANGUAGES).map(({ iso_two_code }) => ({
		key: iso_two_code,
		label: tCommon(`languages.${iso_two_code}`),
	}));

	return (
		<Dropdown
			menu={{
				items: languageVariantButtons,
				onClick: ({ key }) => onPrint(key as ESupportedCountryCodes),
			}}
		>
			<Button>
				<Space size={6}>
					<PrinterOutlined /> {tPrintOrder('actions.print_button')}
				</Space>
			</Button>
		</Dropdown>
	);
};
