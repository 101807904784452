import { SUPPORTED_LANGUAGES } from 'configs/common';
import { ESupportedCountryCodes } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { Flex } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { GlobalOutlined } from '@ant-design/icons';

export const getLanguageMenu = (
	currentLanguage: ESupportedCountryCodes,
	handleLanguageChange: (newLang: ESupportedCountryCodes) => void
): ItemType => {
	const newLanguageConfig =
		currentLanguage === ESupportedCountryCodes.ENGLISH
			? SUPPORTED_LANGUAGES[ESupportedCountryCodes.ARABIC]
			: SUPPORTED_LANGUAGES[ESupportedCountryCodes.ENGLISH];

	return {
		key: 'change_language_cta',
		icon: <GlobalOutlined />,
		label: (
			<Flex
				gap='middle'
				align='center'
			>
				<TranslatedLabel i18nKey={`languages.${newLanguageConfig.iso_two_code}`} />{' '}
				<img
					src={newLanguageConfig.flag}
					alt='flag'
					loading='lazy'
					className='flag_img'
				/>
			</Flex>
		),
		onClick: () => {
			handleLanguageChange(newLanguageConfig.iso_two_code);
		},
	};
};
