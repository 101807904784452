import { FC } from 'react';
import { useLocaleTimeUtils } from './useLocaleTimeUtils';
import dayjs from 'dayjs';

// ! Component for formating dates using useLocaleTimeUtils
export const LocalTimeDate: FC<{
	date: string | Date | dayjs.Dayjs;
	showTimezone?: boolean;
	format?: string;
}> = ({ date, showTimezone, format }) => {
	const { formatDate } = useLocaleTimeUtils();

	return <>{formatDate(date, showTimezone, format)}</>;
};
