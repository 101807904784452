import { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'utils/copyToClipboard';
import styles from './CopyToClipBoard.module.css';
import { Button, Tooltip, TooltipProps } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyToClipBoard: FC<TooltipProps & PropsWithChildren & { contentToCopy?: string; reverted?: boolean }> = ({
	children,
	contentToCopy,
	reverted = true,
	...props
}) => {
	const { t: tCommon } = useTranslation('common', { keyPrefix: 'copy_to_clipboard' });

	// ! state
	const [isLinkCopied, setIsLinkCopied] = useState(false);

	// ! handlers
	const onLinkCopy = async (link = '') => {
		if (link.length) {
			copyToClipboard(link).then(() => {
				setIsLinkCopied(true);
				setTimeout(() => setIsLinkCopied(false), 2000);
			});
		}
	};

	// ! render
	return (
		<Tooltip
			title={tCommon(`${isLinkCopied ? 'copied' : 'copy'}`)}
			{...props}
		>
			<Button
				type='text'
				icon={<CopyOutlined />}
				className={`${styles.copy_button} ${reverted ? styles.reverted : ''}`}
				onClick={() => onLinkCopy(contentToCopy ?? children?.toString())}
			>
				{children}
			</Button>
		</Tooltip>
	);
};

export default CopyToClipBoard;
