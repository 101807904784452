import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IActionsMenuProps } from './types';
import { Button, Space, Switch, Tooltip } from 'antd';

export const ActionsMenu: FC<IActionsMenuProps> = memo(({ actions = [] }) => {
	const { t: tCommon } = useTranslation('common');

	// ! render
	return (
		<Space size='small'>
			{actions.map((action, index) => {
				const WrapperComponent = action.WrapperComponent ?? (({ children }) => <>{children}</>);

				let actionElement: JSX.Element = <></>;

				switch (action.type) {
					case 'button':
						actionElement = (
							<Button
								size='small'
								icon={action.icon}
								disabled={action.disabled}
								className='flex-center'
								onClick={() => (action.actionCb ? action.actionCb() : {})}
							/>
						);
						break;
					case 'switch':
						actionElement = (
							<Switch
								size='small'
								checkedChildren={action.switchCheckedLabel ?? tCommon('switch_states.on')}
								unCheckedChildren={action.switchUncheckedLabel ?? tCommon('switch_states.off')}
								disabled={action.disabled}
								checked={action.switchValue}
								onChange={action.actionCb}
							/>
						);
						break;
					case 'link':
						actionElement = (
							<Link
								to={action.navigateTo ?? ''}
								target={action.target}
							>
								<Button
									size='small'
									icon={action.icon}
									disabled={action.disabled}
									className='flex-center'
								/>
							</Link>
						);
						break;
				}

				return (
					<WrapperComponent key={index}>
						<Tooltip title={action.title}>{actionElement}</Tooltip>
					</WrapperComponent>
				);
			})}
		</Space>
	);
});
