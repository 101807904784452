import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { THttpRequestParams } from './useHttpClient';

export const transformValuesToStrings = (params: any): THttpRequestParams =>
	Object.entries<any>(params).reduce((acc, tuple) => {
		const [key, value] = tuple;
		if ((value && value !== '') || value === 0 || value?.length) {
			if (Array.isArray(value)) {
				acc[key] = [...value.map((v) => v.toString())];
			} else {
				acc[key] = value.toString();
			}
		}

		return acc;
	}, {} as THttpRequestParams);

const groupParamsByKey = (params: URLSearchParams): THttpRequestParams =>
	[...params.entries()].reduce<THttpRequestParams>((acc, tuple) => {
		const [key, value] = tuple;

		if (acc.hasOwnProperty(key)) {
			const existingValue = acc[key];
			acc[key] = Array.isArray(existingValue)
				? // if the current key is already an array, we'll add the value to it
				  [...existingValue, value]
				: // if it's not an array, but contains a value, we'll convert it into an array and add the current value to it
				  [existingValue, value];
		} else {
			// plain assignment if no special case is present
			acc[key] = value;
		}

		return acc;
	}, {});

const useCustomSearchParams = (defaultParams = {}): [URLSearchParams, THttpRequestParams, SetURLSearchParams] => {
	const [params, setParams] = useSearchParams(new URLSearchParams(defaultParams));
	const groupedParams = groupParamsByKey(params);

	return [params, groupedParams, setParams];
};

export { useCustomSearchParams };
