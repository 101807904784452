import { FC } from 'react';
import { ICardRadioGroupProps } from './types';
import styles from './CardRadioGroup.module.css';
import { Card, Col, Row, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export const CardRadioGroup: FC<ICardRadioGroupProps> = ({ value, options, onChange, showSelectedIcon, disabled }) => {
	return (
		<Row gutter={16}>
			{options?.map(({ description, label, icon, value: optionValue }, index) => {
				const isCardSelected = value === optionValue;
				return (
					<Col
						key={index}
						span={options.length === 3 ? 8 : 12}
					>
						<Card
							bordered
							onClick={() => {
								if (!disabled) onChange(optionValue);
							}}
							className={`${styles.incentive_type} ${
								value === optionValue ? styles.incentive_type_selected : ''
							} ${disabled && styles.incentive_type_disabled}`}
						>
							<Row
								gutter={16}
								wrap={false}
							>
								{icon && (
									<Col flex='none'>
										<div className={`${styles.check_icon} ${isCardSelected && styles.active}`}>
											{icon}
										</div>
									</Col>
								)}
								<Col flex='auto'>
									<Typography.Title
										level={4}
										style={{
											margin: 0,
											userSelect: 'none',
										}}
									>
										{label}
									</Typography.Title>
								</Col>

								{showSelectedIcon && (
									<Col flex='none'>
										<div className={value === optionValue ? styles.fade_in_fwd : styles.fade_out}>
											<div className={`${styles.check_icon}  ${styles.active}`}>
												<CheckOutlined style={{ color: 'var(--ant-primary-color)' }} />
											</div>
										</div>
									</Col>
								)}
							</Row>
							<span className='ant-form-item-explain'>{description}</span>
						</Card>
					</Col>
				);
			})}
		</Row>
	);
};
