import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { CONFIGURATIONS_API } from 'configs/api';
import { useHttpClient } from 'hooks';
import { IFirebaseGoogleMapsConfig } from 'types/api';
import { GoogleMapsContext, IGoogleMapsContextData } from './context';

const GoogleMapsProvider: FC<PropsWithChildren> = ({ children }) => {
	const [mapApiKey, setMapApiKey] = useState<IGoogleMapsContextData['mapApiKey']>(null);

	// ! http clients
	const fetchApiKeyClient = useHttpClient<IFirebaseGoogleMapsConfig>();

	// ! effects
	useEffect(() => {
		fetchApiKeyClient.request({
			requestConfig: CONFIGURATIONS_API.getMapConfig(),
			successCallback: (data) => setMapApiKey(data.apiKey),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	const data: IGoogleMapsContextData = {
		mapApiKey,
		mapApiKeyLoading: fetchApiKeyClient.isLoading,
	};

	return <GoogleMapsContext.Provider value={data}>{children}</GoogleMapsContext.Provider>;
};

export default GoogleMapsProvider;
