import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ECampaignFormFields } from 'types/api/promotions';
import { IColumnFiltersSelectProps, TKeyBooleanRecord } from './types';
import styles from './ColumnFilterSelect.module.css';
import { Select } from 'antd';

const ColumnFiltersSelect: FC<IColumnFiltersSelectProps> = ({
	defaultTableColumns,
	selectedColumns,
	setSelectedColumns,
}) => {
	const { t } = useTranslation('common');

	// ! memos
	const tableColumnOptionList = useMemo(
		() =>
			defaultTableColumns.map((item) => ({
				label: item.title as string,
				value: item.key!,
				disabled: item.key === ECampaignFormFields.ID,
			})),
		[defaultTableColumns]
	);

	const defaultValue = useMemo(() => Object.keys(selectedColumns), [selectedColumns]);

	// ! handlers
	const handleFilteredColumnsChange = (value: string[]) => {
		setSelectedColumns(
			value.reduce<TKeyBooleanRecord>((acc, column) => {
				acc[column] = true;

				return acc;
			}, {})
		);
	};

	// ! render
	return (
		<Select
			size='large'
			maxTagCount={0}
			mode='multiple'
			showSearch={false}
			maxTagPlaceholder={t('table.placeholders.filter')}
			options={tableColumnOptionList}
			defaultValue={defaultValue}
			className={styles.table_column_filter}
			onChange={handleFilteredColumnsChange}
		/>
	);
};

export default ColumnFiltersSelect;
