import 'configs/api/promotions';
import { LocalTimeDate } from 'hooks';
import { DATE_FORMAT, DATE_TIME_INTL_FORMAT } from 'utils/date';
import {
	ECampaignFormFields,
	EPromotionDiscountType,
	EPromotionRecommendationStatus,
	EPromotionStatus,
	EPromotionTarget,
	EPromotionUsageType,
} from 'types/api/promotions';
import { EPromotionListActions, IPromotionTableData } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import TranslatedLabel from 'components/TranslatedLabel';
import { PromotionTargetTag } from '../SharedComponents/PromotionTargetTag';
import PromotionStatusTag from '../SharedComponents/PromotionUsageStatusTag';
import { formatDateCampaignDate } from '../SharedComponents/TimeRangeSlider/TimeRangeSlider.configs';
import dayjs from 'dayjs';
import { Space, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { SwapRightOutlined } from '@ant-design/icons';

const TARGET_FILTERS: EPromotionTarget[] = Object.values(EPromotionTarget).map((value) => value);

export const CAMPAIGN_ACTIONS: Record<EPromotionListActions, boolean> = {
	[EPromotionListActions.VIEW]: true,
	[EPromotionListActions.RESUME]: true,
	[EPromotionListActions.PUSH]: false,
	[EPromotionListActions.LAUNCH]: false,
	[EPromotionListActions.PAUSE]: true,
	[EPromotionListActions.STOP]: true,
};

export const PROMOTION_TABLE_COLUMNS: Record<ECampaignFormFields, ColumnType<IPromotionTableData>> = {
	[ECampaignFormFields.ID]: {
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={'id'}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		dataIndex: 'id',
		width: 80,
	},

	[ECampaignFormFields.TARGET]: {
		key: ECampaignFormFields.TARGET,
		ellipsis: false,
		width: 'clamp(130px, 5vw, 300px)',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.TARGET}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		render: (_, { configurations }) => <PromotionTargetTag type={configurations.target} />,
		filters: TARGET_FILTERS.map((value) => ({
			key: value,
			text: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={value}
					extra={{ keyPrefix: `enums.${ECampaignFormFields.TARGET}` }}
				/>
			),
			value,
		})),
	},

	[ECampaignFormFields.TITLE]: {
		key: ECampaignFormFields.TITLE,
		width: 'clamp(180px, 12vw, 300px)',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.TITLE}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		render: (_, { info }) => {
			return info.title;
		},
	},
	[ECampaignFormFields.DESCRIPTION]: {
		key: ECampaignFormFields.DESCRIPTION,
		dataIndex: ECampaignFormFields.DESCRIPTION,
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.DESCRIPTION}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		render: (_, { info }) => {
			return info.description;
		},
	},
	[ECampaignFormFields.CODE]: {
		key: ECampaignFormFields.CODE,
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.CODE}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		render: (_, { configurations }) => {
			return (
				<div style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>
					<CopyToClipBoard placement='topLeft'>{configurations.code}</CopyToClipBoard>
				</div>
			);
		},
	},

	[ECampaignFormFields.TIME_RANGE]: {
		key: ECampaignFormFields.TIME_RANGE,
		width: 'clamp(120px, 6vw, 300px)',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.TIME_RANGE}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		render: (_, { duration }) => {
			const formattedDurationString = formatDateCampaignDate(dayjs.duration(duration.time_range, 'seconds'));

			if (!duration.end_date || !duration.start_date) {
				return <div style={{ whiteSpace: 'nowrap' }}>{formattedDurationString}</div>;
			}

			return (
				<div style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>
					<Tooltip
						placement='leftTop'
						title={
							<Space
								direction='vertical'
								align='center'
								size='small'
							>
								<div style={{ whiteSpace: 'nowrap' }}>
									<LocalTimeDate
										date={duration.start_date}
										format={DATE_TIME_INTL_FORMAT}
									/>
								</div>
								<SwapRightOutlined rotate={90} />
								<LocalTimeDate
									date={duration.end_date}
									format={DATE_TIME_INTL_FORMAT}
								/>
								{formattedDurationString ? (
									<>
										<span>
											<TranslatedLabel
												i18nKey='create.form.time_range.label'
												nameSpace='campaigns'
											/>
										</span>
										{formattedDurationString}
									</>
								) : null}
							</Space>
						}
					>
						<Space
							wrap={false}
							direction='vertical'
						>
							<LocalTimeDate
								date={duration.start_date}
								format={DATE_FORMAT}
							/>
							<SwapRightOutlined rotate={90} />
							<LocalTimeDate
								date={duration.end_date}
								format={DATE_FORMAT}
							/>
						</Space>
					</Tooltip>
				</div>
			);
		},
	},
	[ECampaignFormFields.CREATED_BY]: {
		key: ECampaignFormFields.CREATED_BY,
		width: 'clamp(160px, 10vw, 300px)',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.CREATED_BY}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		render: (_, { authors }) => {
			return authors.created_by.name;
		},
	},
	[ECampaignFormFields.DISCOUNT_TYPE]: {
		key: ECampaignFormFields.DISCOUNT_TYPE,
		ellipsis: false,
		width: 'clamp(170px, 10vw, 300px)',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.DISCOUNT_TYPE}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		filters: Object.values(EPromotionDiscountType).map((value) => ({
			key: value,
			text: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={value}
					extra={{ keyPrefix: `enums.${ECampaignFormFields.DISCOUNT_TYPE}` }}
				/>
			),
			value,
		})),
		render: (_, { configurations }) => (
			<Tag>
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={configurations.discount_type}
					extra={{ keyPrefix: `enums.${ECampaignFormFields.DISCOUNT_TYPE}` }}
				/>
			</Tag>
		),
	},
	[ECampaignFormFields.USER_USAGE_LIMIT]: {
		key: ECampaignFormFields.USER_USAGE_LIMIT,
		dataIndex: ECampaignFormFields.USER_USAGE_LIMIT,
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.USER_USAGE_LIMIT}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
	},
	[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: {
		key: ECampaignFormFields.GLOBAL_USAGE_LIMIT,
		dataIndex: ECampaignFormFields.GLOBAL_USAGE_LIMIT,
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.GLOBAL_USAGE_LIMIT}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
	},
	[ECampaignFormFields.USAGE_TYPE]: {
		key: ECampaignFormFields.USAGE_TYPE,
		width: 'clamp(160px, 10vw, 300px)',
		ellipsis: false,
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.USAGE_TYPE}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		filterMultiple: true,
		filters: Object.values(EPromotionUsageType).map((value) => ({
			key: value,
			value,
			text: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={value}
					extra={{ keyPrefix: `enums.${ECampaignFormFields.USAGE_TYPE}` }}
				/>
			),
		})),
		render: (_, { configurations }) => (
			<TranslatedLabel
				nameSpace='campaigns'
				extra={{ keyPrefix: `form.usage_type.${configurations.usage_type}` }}
				i18nKey='label'
			/>
		),
	},
	[ECampaignFormFields.STATUS]: {
		key: ECampaignFormFields.STATUS,

		width: 'clamp(120px, 4vw, 300px)',
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.STATUS}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		filterMultiple: true,
		filters: Object.values(EPromotionStatus).map((value) => ({
			key: value,
			value,
			text: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={value}
					extra={{ keyPrefix: 'enums.status' }}
				/>
			),
		})),
		render: (_, { info, duration }) => (
			<PromotionStatusTag
				status={info.status}
				startDate={duration.start_date}
			/>
		),
	},
	[ECampaignFormFields.RECOMMENDATION_STATUS]: {
		key: ECampaignFormFields.STATUS,
		title: (
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={ECampaignFormFields.STATUS}
				extra={{ keyPrefix: 'table.headers' }}
			/>
		),
		filterMultiple: true,
		filters: Object.values(EPromotionRecommendationStatus).map((value) => ({
			key: value,
			value,
			text: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={value}
					extra={{ keyPrefix: 'enums.status' }}
				/>
			),
		})),
		render: (_, { info, duration, configurations }) => (
			<PromotionStatusTag
				status={configurations.recommendation_status}
				startDate={duration.start_date}
			/>
		),
	},
	[ECampaignFormFields.TITLE_AR]: {},
	[ECampaignFormFields.DESCRIPTION_AR]: {},
	[ECampaignFormFields.START_DATE]: {},
	[ECampaignFormFields.END_DATE]: {},
	[ECampaignFormFields.RECOMMENDATION_VENDOR_TARGET]: {},
	[ECampaignFormFields.RECOMMENDER]: {},
	[ECampaignFormFields.SOURCE]: {},
	[ECampaignFormFields.MINIMUM_ORDER_AMOUNT]: {},
	[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT]: {},
	[ECampaignFormFields.VENDOR_ID]: {},
	[ECampaignFormFields.STORES_ID]: {},
	[ECampaignFormFields.TYPE]: {},
	[ECampaignFormFields.FREE_DELIVERY]: {},
	[ECampaignFormFields.DISCOUNT_AMOUNT]: {},
	[ECampaignFormFields.INCENTIVE_CURRENCY]: {},
	[ECampaignFormFields.LAUNCH_ON_CREATE]: {},
	[ECampaignFormFields.MULTI_VENDOR]: {},
	[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]: {},
};
