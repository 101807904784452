import { Nullable } from 'types/common';
import { FormInstance } from 'antd';

export const useGetFormValues = () => {
	const getFormValues = async <ReturnType>(form: FormInstance<ReturnType>): Promise<Nullable<ReturnType>> => {
		try {
			return await form.validateFields();
		} catch (error) {
			console.warn(error);
			return null;
		}
	};
	return { getFormValues };
};
