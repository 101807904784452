import { ReactNode } from 'react';
import {
	ECampaignFormFields,
	EPromotionDiscountType,
	EPromotionSource,
	EPromotionStatus,
	EPromotionTarget,
	EPromotionUsageType,
	ICampaignForm,
} from 'types/api/promotions';
import { AimOutlined, FullscreenOutlined, SafetyCertificateOutlined } from '@ant-design/icons';

export const PROMOTION_TARGET_ICON_CONFIGS: Record<EPromotionTarget, ReactNode> = {
	[EPromotionTarget.ACQUISITION]: <AimOutlined />,
	[EPromotionTarget.RETENTION]: <SafetyCertificateOutlined />,
	[EPromotionTarget.DEFAULT]: <FullscreenOutlined />,
};

export const PROMOTION_TARGET_SPECIFIC_DEFAULTS: Partial<
	Record<
		EPromotionTarget,
		Pick<
			ICampaignForm,
			| ECampaignFormFields.DISCOUNT_TYPE
			| ECampaignFormFields.DISCOUNT_AMOUNT
			| ECampaignFormFields.USAGE_TYPE
			| ECampaignFormFields.TIME_RANGE
			| ECampaignFormFields.FREE_DELIVERY
			| ECampaignFormFields.USER_USAGE_LIMIT
		>
	>
> = {
	[EPromotionTarget.ACQUISITION]: {
		[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType.PERCENTAGE,
		[ECampaignFormFields.DISCOUNT_AMOUNT]: 25,
		[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType.MANUAL,
		[ECampaignFormFields.TIME_RANGE]: 14 * 24 * 60 * 60,
		[ECampaignFormFields.FREE_DELIVERY]: true,
		[ECampaignFormFields.USER_USAGE_LIMIT]: 1,
	},
	[EPromotionTarget.RETENTION]: {
		[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType.PERCENTAGE,
		[ECampaignFormFields.DISCOUNT_AMOUNT]: 15,
		[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType.MANUAL,
		[ECampaignFormFields.TIME_RANGE]: 7 * 24 * 60 * 60,
		[ECampaignFormFields.FREE_DELIVERY]: true,
		[ECampaignFormFields.USER_USAGE_LIMIT]: undefined,
	},
};

export const PROMOTION_DEFAULT_VALUES: Partial<ICampaignForm> = {
	[ECampaignFormFields.TARGET]: EPromotionTarget.ACQUISITION,

	[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType.MANUAL,

	[ECampaignFormFields.FREE_DELIVERY]: true,

	[ECampaignFormFields.CODE]: '',

	[ECampaignFormFields.TITLE]: '',
	[ECampaignFormFields.TITLE_AR]: '',
	[ECampaignFormFields.DESCRIPTION]: '',
	[ECampaignFormFields.DESCRIPTION_AR]: '',
	[ECampaignFormFields.START_DATE]: undefined,
	[ECampaignFormFields.END_DATE]: undefined,

	[ECampaignFormFields.TIME_RANGE]: 7 * 24 * 60 * 60,

	[ECampaignFormFields.STATUS]: EPromotionStatus.COMPLETED,
	[ECampaignFormFields.SOURCE]: EPromotionSource.PLATFORM,
	[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType.PERCENTAGE,
	[ECampaignFormFields.AMOUNT]: 0,

	// SPECIFIC DEFAULTS FOR TARGET CAMPAIGN
	...PROMOTION_TARGET_SPECIFIC_DEFAULTS[EPromotionTarget.ACQUISITION],
};
