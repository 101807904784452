import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { CopyToClipBoard } from 'components/CopyToClipBoard';

interface ISkuBarcodeCellProps {
	barcode?: string;
	sku?: string;
}

export const SkuBarcodeCell: FC<ISkuBarcodeCellProps> = ({ barcode, sku }) => {
	const { t: tProductEditor } = useTranslation('product-editor');

	if (!barcode && !sku) {
		return <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>;
	}

	return (
		<table>
			<tbody>
				{barcode && (
					<tr>
						<td>{tProductEditor('table_columns.barcode')}</td>
						<td>:</td>
						<td>
							<CopyToClipBoard reverted={false}>{barcode}</CopyToClipBoard>
						</td>
					</tr>
				)}
				{sku && (
					<tr>
						<td>{tProductEditor('table_columns.sku')}</td>
						<td>:</td>
						<td>
							<CopyToClipBoard reverted={false}>{sku}</CopyToClipBoard>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
