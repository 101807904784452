import { DEFAULT_HOURS_FILTERS } from 'configs/common';
import { createSlice } from '@reduxjs/toolkit';
import { IActionArg, IDefaultStateFields } from '../types';
import { THourRanges } from 'components/HoursFilterGroup/types';
import { DATE_SORT_BUTTONS_CONFIG, DISPATCHER_DEFAULT_ACTIVE_TAB } from 'pages/Dispatcher/configs';
import { EDispatcherTab } from 'pages/Dispatcher/types';

// ! initial state
interface IDispatcherSlice extends IDefaultStateFields {
	dateSort: string;
	hoursFilter: THourRanges;
	activeStatusTabKey: EDispatcherTab;
	isDataRefreshEnable: boolean;
}

const dispatcherSliceInitialState: IDispatcherSlice = {
	isDataRefreshEnable: true,
	dateSort: DATE_SORT_BUTTONS_CONFIG[1].key,
	hoursFilter: DEFAULT_HOURS_FILTERS[0],
	activeStatusTabKey: DISPATCHER_DEFAULT_ACTIVE_TAB,

	// own state
	loading: false,
};

// ! slice
const dispatcherSlice = createSlice({
	name: 'dispatcherSlice',
	initialState: dispatcherSliceInitialState,
	reducers: {
		setDateSort: (state, action: IActionArg<string>) => {
			state.dateSort = action.payload;
		},
		setHoursFilter: (state, action: IActionArg<THourRanges>) => {
			state.hoursFilter = action.payload;
		},
		setActiveStatusTab: (state, action: IActionArg<EDispatcherTab>) => {
			state.activeStatusTabKey = action.payload;
		},
		toggleDataRefresh: (state, action: IActionArg<boolean>) => {
			state.isDataRefreshEnable = action.payload;
		},
	},
	extraReducers: () => {},
});

export const { setActiveStatusTab, setDateSort, setHoursFilter, toggleDataRefresh } = dispatcherSlice.actions;

export default dispatcherSlice.reducer;
