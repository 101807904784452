import { Property } from 'csstype';
import { EPromotionRecommendationStatus, EPromotionStatus } from 'types/api/promotions';
import { TFinalStatus } from './types';

export const PROMOTION_STATUS_TAG_COLORS: Record<TFinalStatus, Property.Color> = {
	[EPromotionStatus.PENDING]: 'orange',
	[EPromotionStatus.PAUSED]: 'yellow',
	[EPromotionStatus.IN_PROGRESS]: 'lime',
	[EPromotionStatus.COMPLETED]: 'green',
	[EPromotionRecommendationStatus.ACTIVE]: 'lime',
	[EPromotionRecommendationStatus.INACTIVE]: 'yellow',
	[EPromotionRecommendationStatus.EXPIRED]: 'orange',
	[EPromotionRecommendationStatus.USED]: 'grey',
	scheduled: 'purple',
};
