import { THttpRequestConfig, THttpRequestParams } from 'hooks/useHttpClient';
import { EIsSubCategory, IVendorAddCategoryPayload, IVendorUpdateCategoryPayload } from 'types/api';

export const VENDOR_CATEGORIES_API = {
	list: (
		vendorId: number,
		filterBySubCategory: EIsSubCategory = EIsSubCategory.NO,
		search?: string,
		params?: THttpRequestParams
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/categories`,
		method: 'GET',
		params: {
			search,
			is_sub_category: filterBySubCategory,
			...params,
		},
	}),

	// ! CRUD
	create: (vendorId: number, data: IVendorAddCategoryPayload): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/categories`,
		method: 'POST',
		data,
	}),
	update: (vendorId: number, { id: categoryId, ...data }: IVendorUpdateCategoryPayload): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/categories/${categoryId}`,
		method: 'PATCH',
		data,
	}),
	delete: (vendorId: number, categoryId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/categories/${categoryId}`,
		method: 'DELETE',
	}),

	// ! others
	uploadImage: (vendorId: number, categoryId: number, data: FormData): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/categories/${categoryId}/images`,
		method: 'POST',
		data,
	}),

	// global
	getGlobalCategoryList: (search?: string): THttpRequestConfig => ({
		url: `vendor/global-categories`,
		method: 'GET',
		params: {
			search,
		},
	}),
};
