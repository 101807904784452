import { AxiosResponse } from 'axios';
import { COUNTRY_API } from 'configs/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { ICountry } from 'types/api';
import { IDefaultStateFields } from '../types';

// ! initial state
export interface ICountriesSlice extends IDefaultStateFields {
	countriesList: ICountry[];
}

const countriesSliceInitialState: ICountriesSlice = {
	countriesList: [],
	loading: false,
};

// ! thunks
export const fetchCountries = createAsyncThunk<
	ICountry[],
	{
		http: THttpFunction;
	},
	{ state: RootState }
>('countriesSlice/fetchCountries', async ({ http }, { rejectWithValue }) => {
	try {
		const prom: AxiosResponse<Array<ICountry>> = await http(COUNTRY_API.getList());

		return prom.data;
	} catch (error) {
		console.error(error);
		return rejectWithValue(error);
	}
});

const countriesSlice = createSlice({
	name: 'countriesSlice',
	initialState: countriesSliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCountries.pending, pendingCase)
			.addCase(fetchCountries.rejected, errorCase)
			.addCase(fetchCountries.fulfilled, (state, { payload: countryList }) => {
				state.countriesList = countryList;
				state.loading = false;
			});
	},
});

export default countriesSlice.reducer;
