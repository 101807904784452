import { Property } from 'csstype';
import { EPromotionTarget } from 'types/api/promotions';

export type TRecommendIconColors = {
	background: Property.Color;
	color: Property.Color;
};

export const promotion_target_config: Record<EPromotionTarget, TRecommendIconColors> = {
	[EPromotionTarget.DEFAULT]: { background: 'var(--ant-primary-2)', color: 'var(--ant-primary-color)' },
	[EPromotionTarget.RETENTION]: { background: '#D1FADF', color: '#12B76A' },
	[EPromotionTarget.ACQUISITION]: { background: 'var(--ant-primary-2)', color: 'var(--ant-primary-color)' },
};
