import { DEFAULT_USER_INTERACTION_DEBOUNCE } from './debounce';

export const throttle = <R, A extends any[]>(
	fn: (...args: A) => R,
	delay: number = DEFAULT_USER_INTERACTION_DEBOUNCE
): ((...args: A) => R | undefined) => {
	let wait = false;
	let cancelled = false;

	return (...args: A) => {
		if (cancelled || wait) return undefined;

		const val = fn(...args);

		wait = true;

		window.setTimeout(() => {
			wait = false;
		}, delay);

		return val;
	};
};
