import { FC } from 'react';
import styles from './CustomTabs.module.css';
import { Tabs, TabsProps } from 'antd';

export const CustomTabs: FC<TabsProps> = ({ type, ...props }) => (
	<Tabs
		className={styles.tabs_container}
		{...props}
	/>
);
