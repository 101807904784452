import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CODE_PERCENTAGE_OPTIONS, CODE_PERCENTAGE_OPTION_OTHER, MINIMUM_DISCOUNT_AMOUNT } from './configs';
import { ECampaignFormFields } from 'types/api/promotions';
import { ICampaignCreateController } from '../../hooks/useCampaignCreateController';
import { Form, InputNumber, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Segmented, { SegmentedValue } from 'antd/lib/segmented';

const PercentageInput: FC<{ controller: ICampaignCreateController }> = ({ controller }) => {
	const { t: tCampaigns } = useTranslation('campaigns');
	const {
		form,
		formData: { discount_amount },
	} = controller;

	const [isOtherPercentageSelected, setIsOtherPercentageSelected] = useState(false);

	useEffect(() => {
		setIsOtherPercentageSelected(!CODE_PERCENTAGE_OPTIONS.find((val) => val.value === discount_amount));
	}, [discount_amount]);

	return (
		<Space
			className='w-100'
			direction='vertical'
		>
			<div style={{ width: 'fit-content' }}>
				<FormItem
					wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
					label={tCampaigns(`create.form.${ECampaignFormFields.DISCOUNT_AMOUNT}.label`)}
					extra={tCampaigns(`create.form.${ECampaignFormFields.DISCOUNT_AMOUNT}.description`)}
				>
					<Space className='w-100'>
						<Segmented
							disabled={false}
							options={CODE_PERCENTAGE_OPTIONS}
							block={false}
							size='large'
							value={
								CODE_PERCENTAGE_OPTIONS.find((el) => el.value === discount_amount)?.value ??
								CODE_PERCENTAGE_OPTION_OTHER
							}
							onChange={(value: SegmentedValue) => {
								setIsOtherPercentageSelected(value === CODE_PERCENTAGE_OPTION_OTHER);
								if (value !== CODE_PERCENTAGE_OPTION_OTHER) {
									form.setFieldValue(ECampaignFormFields.DISCOUNT_AMOUNT, value);
								}
							}}
						/>
						<Form.Item
							noStyle
							hidden={!isOtherPercentageSelected}
							name={ECampaignFormFields.DISCOUNT_AMOUNT}
							rules={[
								{ required: true },
								() => ({
									validator(_, value) {
										if (!value || value < MINIMUM_DISCOUNT_AMOUNT) {
											return Promise.reject(
												new Error(
													tCampaigns(
														'create.form.discount_type.validations.less_then_minimum',
														{
															min: MINIMUM_DISCOUNT_AMOUNT,
														}
													)
												)
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<InputNumber
								type='number'
								size='large'
								max={100}
								min={0}
								suffix='%'
							/>
						</Form.Item>
					</Space>
				</FormItem>
			</div>
		</Space>
	);
};

export default PercentageInput;
