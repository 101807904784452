import dayjs, { Dayjs } from 'dayjs';

export enum EPromotionType {
	CODE = 'code',
	RECOMMENDATION = 'recommendation',
	CAMPAIGN = 'campaign',
}

export enum EPromotionSource {
	PLATFORM = 'platform',
	VENDOR = 'vendor',
}

export enum EPromotionUsageType {
	MANUAL = 'manual',
	AUTOMATIC = 'automatic',
}

export enum EPromotionDiscountType {
	PERCENTAGE = 'percentage',
	AMOUNT = 'amount',
	FREE_DELIVERY = 'free_delivery',
	CASHBACK_PERCENTAGE = 'cashback_percentage',
	CASHBACK_AMOUNT = 'cashback_amount',
}

export enum EPromotionTarget {
	DEFAULT = 'default',
	RETENTION = 'retention',
	ACQUISITION = 'acquisition',
}

export enum EPromotionStatus {
	PAUSED = 'paused',
	PENDING = 'pending',
	IN_PROGRESS = 'in_progress',
	COMPLETED = 'completed',
}

export enum EPromotionRecommendationStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	EXPIRED = 'expired',
	USED = 'used',
}

interface IPromotionActions {
	can_launch: boolean;
	can_pause: boolean;
	can_reactivate: boolean;
	can_resume: boolean;
	can_revoke: boolean;
	can_stop: boolean;
	can_edit: boolean;
	can_update_end_date: boolean;
}

// FE TYPES

export enum ECampaignFormFields {
	ID = 'id',
	TYPE = 'type',
	TARGET = 'target',
	USAGE_TYPE = 'usage_type',
	FREE_DELIVERY = 'free_delivery',
	DISCOUNT_AMOUNT = 'discount_amount',
	INCENTIVE_CURRENCY = 'incentive_currency',

	TITLE = 'title',
	TITLE_AR = 'title_ar',
	DESCRIPTION = 'description',
	DESCRIPTION_AR = 'description_ar',
	CODE = 'code',

	TIME_RANGE = 'time_range',
	START_DATE = 'start_date',
	END_DATE = 'end_date',

	RECOMMENDATION_VENDOR_TARGET = 'recommendation_vendor_target',

	RECOMMENDER = 'recommender',
	CREATED_BY = 'created_by',
	STATUS = 'status',
	RECOMMENDATION_STATUS = 'recommendation_status',
	SOURCE = 'source',
	DISCOUNT_TYPE = 'discount_type',
	AMOUNT = 'discount_amount',
	MINIMUM_ORDER_AMOUNT = 'min_order_amount',
	MAXIMUM_DISCOUNT_AMOUNT = 'max_discount_amount',
	GLOBAL_USAGE_LIMIT = 'global_usage_limit',
	USER_USAGE_LIMIT = 'user_usage_limit',

	LAUNCH_ON_CREATE = 'launch_on_create',

	VENDOR_ID = 'vendor_id',

	STORES_ID = 'stores_id',

	MULTI_VENDOR = 'vendor_list',
	MULTI_VENDOR_VENDOR_ID = 'id',
	MULTI_VENDOR_BRANCH_LIST = 'store_list',
}

interface ICampaignVendorFormField {
	[ECampaignFormFields.MULTI_VENDOR_VENDOR_ID]: number;
	[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]: number[];
}

export interface ICampaignForm {
	id?: string | number;

	[ECampaignFormFields.TYPE]: EPromotionType;
	[ECampaignFormFields.TARGET]: EPromotionTarget;

	[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType;

	// TO QUESTION:
	[ECampaignFormFields.FREE_DELIVERY]: boolean;

	[ECampaignFormFields.RECOMMENDATION_VENDOR_TARGET]: string;

	[ECampaignFormFields.CODE]: string;

	[ECampaignFormFields.TITLE]: string;
	[ECampaignFormFields.TITLE_AR]: string;
	[ECampaignFormFields.DESCRIPTION]: string;
	[ECampaignFormFields.DESCRIPTION_AR]: string;
	[ECampaignFormFields.START_DATE]: dayjs.Dayjs;
	[ECampaignFormFields.END_DATE]: dayjs.Dayjs;
	[ECampaignFormFields.TIME_RANGE]?: number;

	// calculation fields
	[ECampaignFormFields.STATUS]: EPromotionStatus;
	[ECampaignFormFields.RECOMMENDATION_STATUS]: EPromotionRecommendationStatus;

	[ECampaignFormFields.SOURCE]: EPromotionSource;
	[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType;
	[ECampaignFormFields.AMOUNT]: number;
	[ECampaignFormFields.MINIMUM_ORDER_AMOUNT]: number;
	[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT]: number;
	[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: number;
	[ECampaignFormFields.USER_USAGE_LIMIT]?: number;

	[ECampaignFormFields.MULTI_VENDOR]: Array<ICampaignVendorFormField>;
}

interface IPromotionTargetEntity {
	name: string;
	id: number;
}

interface IPromotionRecommendedBy {
	id: number;
	name: string;
}

// BE => OperationsPromotionResponse

export interface IPromotionVendorDetails extends IPromotionTargetEntity {
	[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]?: Array<IPromotionTargetEntity>;
}

export interface IPromotionGenerateCodeResponse {
	[ECampaignFormFields.CODE]: string;
}

export interface IPromotionCreatePayloadTemp {
	[ECampaignFormFields.CODE]: string;
	[ECampaignFormFields.TARGET]: EPromotionTarget;
	[ECampaignFormFields.TYPE]: EPromotionType;

	[ECampaignFormFields.START_DATE]?: string;
	[ECampaignFormFields.END_DATE]?: string;
	[ECampaignFormFields.TIME_RANGE]?: number;

	[ECampaignFormFields.DISCOUNT_TYPE]: EPromotionDiscountType;
	[ECampaignFormFields.DISCOUNT_AMOUNT]: number;
	[ECampaignFormFields.TITLE]: string;
	[ECampaignFormFields.TITLE_AR]: string;
	[ECampaignFormFields.DESCRIPTION]: string;
	[ECampaignFormFields.DESCRIPTION_AR]: string;
	[ECampaignFormFields.USAGE_TYPE]: EPromotionUsageType;

	min_order_amount: number;
	max_discount_amount: number;
	usage_limit_per_user?: number;
	[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: number;

	[ECampaignFormFields.FREE_DELIVERY]: boolean;

	[ECampaignFormFields.STATUS]: EPromotionStatus;

	[ECampaignFormFields.LAUNCH_ON_CREATE]: boolean;

	[ECampaignFormFields.MULTI_VENDOR]: Array<ICampaignVendorFormField>;
}

// ! NEW TYPES
export interface IPromotionListInfo {
	title: string;
	title_ar: string;
	description: string;
	description_ar: string;
	status: EPromotionStatus;
}

interface IPromotionCreatedBy {
	id: number;
	name: string;
}

interface IPromotionAuthors {
	created_by: IPromotionCreatedBy;
	recommender: IPromotionRecommendedBy;
}

interface IPromotionConfigurations {
	code: string;
	type: EPromotionType;
	target: EPromotionTarget;
	recommendation_status: EPromotionRecommendationStatus;
	discount_type: EPromotionDiscountType;
	usage_type: EPromotionUsageType;
	discount_amount: number;
	min_order_amount: number;
	max_discount_amount: number;
	free_delivery: boolean;
}

export interface IPromotionDuration {
	start_date: Dayjs;
	end_date: Dayjs;
	time_range: number;
}

interface IPromotionUsage {
	global_usage_limit: number;
	user_usage_limit: number;
}

export interface IPromotionListResponse {
	id: number;
	info: IPromotionListInfo;
	authors: IPromotionAuthors;
	configurations: IPromotionConfigurations;
	duration: IPromotionDuration;
	actions: IPromotionActions;
	usage: IPromotionUsage;
	vendor_list: Array<IPromotionVendorDetails>;
}
