import { ITabItemConfig } from 'types/common';

export enum EVendorBranchTabsKeys {
	DETAILS = 'details',
	MENU = 'menu',
	WORK_SHIFT = 'work-shift',
	OPERATIONS_AREA = 'operations-area',
}

export interface IVendorInfoTabItem extends ITabItemConfig {
	key: EVendorBranchTabsKeys;
}
