import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedInformation, useBranchDrawer } from 'hooks';
import { Button, Space, Tooltip } from 'antd';
import { ShopOutlined } from '@ant-design/icons';

export const BranchSelector: FC = () => {
	const { user, selectedBranchIdList: branchIdList } = useAuthenticatedInformation();
	const { t: tCommon } = useTranslation('common');
	const { openDrawer } = useBranchDrawer();

	const branchesInfo = user.stores.filter((store) => {
		return branchIdList.includes(store.id);
	});

	return (
		<Tooltip title={tCommon('header.branch_selection.tooltip')}>
			<Button
				size='small'
				onClick={() => openDrawer()}
			>
				<Space>
					<Space size='small'>
						<ShopOutlined />
						{tCommon('header.branch_selection.selected', {
							count: branchesInfo.length,
							id: branchesInfo[0]?.id,
							name: branchesInfo[0]?.name,
						})}
					</Space>
				</Space>
			</Button>
		</Tooltip>
	);
};
