import { FC, ReactNode, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { useAuth } from 'hooks';
import { EVendorUserType } from 'types/api';

export const ProtectedAccessByRoleWrapper: FC<{
	children?: ReactNode;
	allowRoles: EVendorUserType[];
	redirectToNoAccessRoute?: boolean;
}> = ({ allowRoles, redirectToNoAccessRoute = true, children = <Outlet /> }) => {
	const { user } = useAuth();

	const isRouteAccessible = useMemo(() => {
		if (!user) return false;

		return allowRoles.length === 0 || allowRoles.includes(user.type);
	}, [allowRoles, user]);

	if (!user) return null;

	if (isRouteAccessible) {
		return <>{children}</>;
	}

	if (redirectToNoAccessRoute) {
		return (
			<Navigate
				to={ABSOLUTE_ROUTES.NO_ACCESS_ROUTE}
				replace
			/>
		);
	}

	return null;
};
