import { FC } from 'react';
import useLogos from 'hooks/useLogos';
import { ISpinnerProps } from './types';
import styles from './Spinner.module.css';
import { Spin } from 'antd';

export const Spinner: FC<ISpinnerProps> = ({ defaultAntdSpinner, size = 'large', hasOverlay = true }) => {
	const { miniLogo } = useLogos();

	const shouldDefaultAntdSpin = defaultAntdSpinner || !miniLogo;

	// ! render
	return (
		<div className={styles.loader_container}>
			{hasOverlay && <div className={styles.overlay}></div>}
			{shouldDefaultAntdSpin ? (
				<Spin
					size={size}
					className={styles.default_spinner}
				/>
			) : (
				<img
					src={miniLogo}
					className={styles.loader}
					alt='logo'
				/>
			)}
		</div>
	);
};
