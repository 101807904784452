import { IPromotionListResponse } from 'types/api/promotions';
import { IBaseTableData } from 'components/Table';

export enum EPromotionListActions {
	VIEW = 'view',
	RESUME = 'resume',
	PUSH = 'push',
	LAUNCH = 'launch',
	PAUSE = 'pause',
	STOP = 'stop',
}

export interface IPromotionTableData extends IPromotionListResponse, IBaseTableData {}
