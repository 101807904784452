import TranslatedLabel from 'components/TranslatedLabel';
import { SegmentedLabeledOption } from 'antd/es/segmented';

export const MINIMUM_DISCOUNT_AMOUNT = 5;

export const CODE_PERCENTAGE_OPTION_OTHER = (
	<TranslatedLabel
		nameSpace='campaigns'
		i18nKey='create.form.discount_amount.option.other'
	/>
) as unknown as string;

export const CODE_PERCENTAGE_OPTIONS: SegmentedLabeledOption[] = [10, 20, 30, 40, 50, CODE_PERCENTAGE_OPTION_OTHER].map(
	(option) => ({ label: option !== CODE_PERCENTAGE_OPTION_OTHER ? `${option}%` : option, value: option })
);
