import { ReactNode } from 'react';
import { EOnOffStatus } from 'types/common';

export enum EBranchSelectionTableData {
	VENDOR = 'vendor',
	BRANCH = 'branch',
}

export interface IBranchSelectionTableData {
	key: React.Key;
	id: React.Key;
	branchId?: number;
	vendorId: number;
	type: EBranchSelectionTableData;
	name: ReactNode;
	status: EOnOffStatus;
	children?: IBranchSelectionTableData[];
}
