import { FC, useEffect, useMemo, useState } from 'react';
import { ORDERS_API } from 'configs/api';
import { useHttpClient, useLocaleTimeUtils } from 'hooks';
import { IOrderLog } from 'types/api';
import { IListResponse } from 'types/common';
import useOrderContext from 'pages/Orders/useOrderContext';
import OrderTimeline, { reduceTimelineData } from './OrderTimeline';
import { Card } from 'antd';

const TimelineTab: FC = () => {
	const { orderId } = useOrderContext();
	const { formatDate } = useLocaleTimeUtils();

	// ! state
	const [timelineResponse, setTimelineResponse] = useState<IOrderLog[]>([]);

	// ! http clients
	const { request: getOrderHistoryRequest, isLoading } = useHttpClient<IListResponse<IOrderLog>>();

	// ! memos
	const timelineItems = useMemo(
		() => reduceTimelineData(timelineResponse, formatDate),
		[formatDate, timelineResponse]
	);

	// ! useEffects
	useEffect(() => {
		const ctrl = new AbortController();

		getOrderHistoryRequest(
			{
				requestConfig: ORDERS_API.getHistory(orderId),
				successCallback: ({ data }) => setTimelineResponse(data),
			},
			ctrl.signal
		);

		return () => {
			ctrl.abort();
		};
	}, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<Card
			key='timeline-info'
			loading={isLoading}
		>
			<OrderTimeline items={timelineItems} />
		</Card>
	);
};

export default TimelineTab;
