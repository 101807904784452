import { THttpRequestConfig } from 'hooks/useHttpClient';
import { IInvoiceParams } from 'types/api';

export const INVOICES_API = {
	list: (params?: IInvoiceParams): THttpRequestConfig => ({
		url: 'vendor/invoices',
		method: 'GET',
		params,
	}),
};
