import { DATE_TIME_INTL_FORMAT } from 'utils/date';
import dayjs from 'dayjs';

export const DEFAULT_DASHBOARD_START_DATE = dayjs().startOf('day');

export const DEFAULT_DASHBOARD_END_DATE = dayjs().endOf('day');

export const DEFAULT_DASHBOARD_URL_PARAMS = {
	start_date: DEFAULT_DASHBOARD_START_DATE.format(DATE_TIME_INTL_FORMAT),
	end_date: DEFAULT_DASHBOARD_END_DATE.format(DATE_TIME_INTL_FORMAT),
};
