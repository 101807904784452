import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DESCRIPTIONS_PROPS, DEFAULT_EMPTY_VALUE_PLACEHOLDER, KSA_COUNTRY_ISO_CODE } from 'configs/common';
import { PRODUCT_DETAILS_COLUMNS } from './configs';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils/LocalTimeDate';
import { transformProductDetailsToTableData } from './helper';
import { TIME_FORMAT } from 'utils/date';
import {
	EOrderBookingTypes,
	EOrderPaymentMethods,
	EOrderPaymentStatus,
	EOrderStatus,
	EOrderWalletComplementAmountRate,
} from 'types/api';
import { Nullable } from 'types/common';
import { IProductDetailsTableData } from './types';
import AddressLinkButton from 'components/AddressLinkButton';
import BookingTypeLabel from 'components/BookingTypeLabel';
import BranchLink from 'components/BranchLink';
import CustomDivider from 'components/CustomDivider';
import OrderTypeTag from 'components/Tags/OrderTypeTag';
import PaymentStatusTag from 'components/Tags/PaymentStatusTag';
import { TextSplitter } from 'components/TextSplitter';
import OrderPrint from 'components/printableComponents/OrderPrint';
import useOrderContext from 'pages/Orders/useOrderContext';
import styles from './OrderInfo.module.css';
import PaymentTypeLabel from './PaymentTypeLabel';
import ProductDetailsSummary, { IProductsTableSummary, getTableSummary } from './ProductDetailsSummary';
import { Alert, Card, Col, Descriptions, Flex, Row, Space, Table } from 'antd';
import { CarOutlined, ClockCircleOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

export const OrderInfo: FC = () => {
	const { t: tOrders } = useTranslation('orders');

	const [productDetails, setProductDetails] = useState<Nullable<IProductDetailsTableData[]>>(null);
	const [tableSummaryData, setTableSummaryData] = useState<IProductsTableSummary[]>([]);

	const { data: order, fetchData } = useOrderContext();

	// ! useEffects
	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (order === null) return;

		const productList = transformProductDetailsToTableData(order.products);
		setProductDetails(productList);

		const summary = getTableSummary(order);
		setTableSummaryData(summary);
	}, [order]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	const { info, store, customer, payment, delivery_address, driver, actions } = order;

	return (
		<div className={`order-details ${styles.main_container}`}>
			<Space
				size='large'
				direction='vertical'
			>
				{info.status === EOrderStatus.SCHEDULED && (
					<Alert
						message={tOrders('order_details.tabs.details.alerts.scheduled_order')}
						type='warning'
						showIcon
					/>
				)}
				{/* ORDER MAIN INFO */}
				<Row gutter={[16, 16]}>
					<Col
						key='col-1'
						span={12}
					>
						<Card
							key='order-info'
							className='h-100'
						>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tOrders('shared.entity.branch')}>
									<BranchLink {...store} />
								</Descriptions.Item>

								<Descriptions.Item label={tOrders('shared.entity.placed_at')}>
									<Space size={6}>
										<ClockCircleOutlined />
										<LocalTimeDate date={info.placed_at} />
									</Space>
								</Descriptions.Item>

								{order.info.booking_type !== EOrderBookingTypes.SCHEDULED && (
									<>
										<Descriptions.Item
											label={tOrders('shared.entity.expected_delivery_time.label')}
										>
											{tOrders('shared.entity.expected_delivery_time.value', {
												min: store.min_order_delivery_time,
												max: store.max_order_delivery_time,
											})}
										</Descriptions.Item>
										<Descriptions.Item label={tOrders('shared.entity.expected_time_arrival')}>
											<Space size={6}>
												<ClockCircleOutlined />
												<LocalTimeDate
													format={TIME_FORMAT}
													date={order.info.min_customer_delivery_eta_at}
												/>
												-
												<LocalTimeDate
													format={TIME_FORMAT}
													date={order.info.max_customer_delivery_eta_at}
												/>
											</Space>
										</Descriptions.Item>
									</>
								)}

								<Descriptions.Item label={tOrders('shared.entity.order_type')}>
									{actions.can_show_order_type && info.type ? (
										<OrderTypeTag type={info.type} />
									) : (
										DEFAULT_EMPTY_VALUE_PLACEHOLDER
									)}
								</Descriptions.Item>

								<Descriptions.Item label={tOrders('shared.entity.customer')}>
									<Space size={4}>
										<UserOutlined />
										{customer.name ? (
											<>
												{customer.name} | {customer.contact_number}
											</>
										) : (
											<>{customer.id}</>
										)}
									</Space>
								</Descriptions.Item>
							</Descriptions>
						</Card>
					</Col>

					<Col
						key='col-2'
						span={12}
					>
						<Card
							key='payment-info'
							className={
								store.country.iso_two_code !== KSA_COUNTRY_ISO_CODE
									? payment.status === EOrderPaymentStatus.PAID
										? styles.payment_success_card
										: styles.payment_warning_card
									: 'h-100'
							}
						>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tOrders('shared.entity.total_cost')}>
									{payment.grand_total} {payment.currency_code}
								</Descriptions.Item>

								{store.country.iso_two_code !== KSA_COUNTRY_ISO_CODE && (
									<>
										<Descriptions.Item label={tOrders('shared.entity.payment_status')}>
											<PaymentStatusTag status={payment.status} />
										</Descriptions.Item>

										{payment.should_display_payment_method && (
											<Descriptions.Item label={tOrders('shared.entity.payment_type')}>
												<Space split={<TextSplitter />}>
													{payment.wallet_complement_amount_rate ===
														EOrderWalletComplementAmountRate.PARTIAL && (
														<PaymentTypeLabel
															paymentMethod={EOrderPaymentMethods.WALLET}
															amount={payment.wallet_complement_amount}
															currency={payment.currency_code}
														/>
													)}
													<PaymentTypeLabel
														paymentMethod={payment.method}
														amount={payment.amount_payable}
														currency={payment.currency_code}
													/>
												</Space>
											</Descriptions.Item>
										)}

										{payment.status === EOrderPaymentStatus.PAID && (
											<Descriptions.Item label={tOrders('shared.entity.payment_date')}>
												<Space size={6}>
													<ClockCircleOutlined />
													<LocalTimeDate date={payment.paid_at} />
												</Space>
											</Descriptions.Item>
										)}
									</>
								)}

								<Descriptions.Item className={styles.payment_invoice_button}>
									<Space split={<CustomDivider type='vertical' />}>
										<OrderPrint orderId={order.id} />
									</Space>
								</Descriptions.Item>
							</Descriptions>
						</Card>
					</Col>

					<Col
						key='col-3'
						flex='auto'
					>
						<Card key='delivery-info'>
							<Descriptions
								layout='vertical'
								{...DEFAULT_DESCRIPTIONS_PROPS}
							>
								{info.booking_type && (
									<Descriptions.Item label={tOrders('shared.entity.booking_type')}>
										<BookingTypeLabel bookingType={info.booking_type} />
									</Descriptions.Item>
								)}

								<Descriptions.Item label={tOrders('shared.entity.pickup_time')}>
									<Space size={6}>
										<ClockCircleOutlined />
										{info.pickup_time ? (
											<LocalTimeDate date={info.pickup_time} />
										) : (
											DEFAULT_EMPTY_VALUE_PLACEHOLDER
										)}
									</Space>
								</Descriptions.Item>

								<Descriptions.Item label={tOrders('shared.entity.pickup_address')}>
									<AddressLinkButton
										icon={<ShopOutlined />}
										address={store.address}
										addressLatLng={`${store.lat},${store.lng}`}
									/>
								</Descriptions.Item>

								<Descriptions.Item label={tOrders('shared.entity.delivery_address')}>
									{delivery_address?.address ? (
										<AddressLinkButton
											icon={<UserOutlined />}
											address={delivery_address.address}
											addressLatLng={`${delivery_address.lat},${delivery_address.lng}`}
										/>
									) : (
										<Flex gap={6}>
											<UserOutlined />
											{DEFAULT_EMPTY_VALUE_PLACEHOLDER}
										</Flex>
									)}
								</Descriptions.Item>

								<Descriptions.Item label={tOrders('shared.entity.delivery_person')}>
									<Flex gap={6}>
										<CarOutlined /> {driver?.name ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER}
									</Flex>
								</Descriptions.Item>

								{info.additional_info && (
									<Descriptions.Item label={tOrders('shared.entity.additional_info')}>
										{info.additional_info}
									</Descriptions.Item>
								)}
							</Descriptions>
						</Card>
					</Col>
				</Row>

				{/* PRODUCT DETAILS */}
				<Row>
					<CustomDivider plain>{tOrders('order_details.tabs.details.product_details.title')}</CustomDivider>

					{productDetails !== null && (
						<Table
							size='middle'
							tableLayout='fixed'
							pagination={false}
							dataSource={productDetails}
							expandable={{
								defaultExpandAllRows: true,
								childrenColumnName: 'options',
							}}
							columns={PRODUCT_DETAILS_COLUMNS}
							summary={() => <ProductDetailsSummary summary={tableSummaryData} />}
						/>
					)}
				</Row>
			</Space>
		</div>
	);
};
