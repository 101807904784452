import { RouteObject } from 'react-router-dom';
import { IRoute } from 'configs/routes';

export const getNavItems = (routes: RouteObject[], parentRoute?: string): IRoute[] => {
	const result = routes.reduce<IRoute[]>((acc, route) => {
		if (route.handle?.showOnNav) {
			const newRoutePath = (parentRoute ? `${parentRoute}/` : '') + route.path;

			const childrenNavRoutes = getNavItems(route.children ?? [], newRoutePath);

			const routeToBePushed: IRoute = {
				id: route.id,
				path: route.path,
				translationKey: route.handle.translationKey,
				icon: route.handle.icon,
				showOnNav: route.handle.showOnNav,
				children: childrenNavRoutes.length === 0 ? undefined : childrenNavRoutes,
				defaultListParams: route.handle.defaultListParams,
			};

			if (route.index) {
				routeToBePushed.path = parentRoute + '/';
			} else {
				routeToBePushed.path = newRoutePath;
			}

			acc.push(routeToBePushed);
		}
		return acc;
	}, []);

	return result;
};
