import { FC, ReactNode, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { EOrderBookingTypes } from 'types/api';
import { IBookingTypeLabelProps } from './types';
import { Space, Tooltip } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

export const BookingTypeLabel: FC<IBookingTypeLabelProps> = memo(({ bookingType, pickupTime }) => {
	const { t: tDispatcher } = useTranslation('dispatcher');
	const { t: tCommon } = useTranslation('common');

	let label: ReactNode = tCommon(`order.booking_types.${bookingType}`);

	if (bookingType === EOrderBookingTypes.SCHEDULED && pickupTime?.length) {
		label = (
			<Trans
				t={tDispatcher}
				i18nKey='order_card.booking_type_schedule'
				values={{ bookingType: label }}
				components={[<LocalTimeDate date={pickupTime} />]}
			/>
		);
	}

	// ! render
	return (
		<Tooltip title={tDispatcher('order_card.booking_type')}>
			<Space size={6}>
				<CalendarOutlined />
				{label}
			</Space>
		</Tooltip>
	);
});
