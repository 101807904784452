import { ReactNode, createContext, useContext } from 'react';
import { IUseAppTitle, useAppTitle } from 'hooks/useDocumentTitle';

export const AppTitleContext = createContext({} as IUseAppTitle);
export const AppTitleProvider = ({ children }: { children: ReactNode }) => {
	const useAppDocumentHookActions = useAppTitle(document.title);

	return <AppTitleContext.Provider value={useAppDocumentHookActions}>{children}</AppTitleContext.Provider>;
};

const useAppTitleProvider = () => {
	return useContext(AppTitleContext);
};

export default useAppTitleProvider;
