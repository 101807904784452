import { FC, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Property } from 'csstype';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EOrderType } from 'types/api';
import { IOrderTypeTagProps } from './types';
import { Tag } from 'antd';
import { SwapOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

const TAG_ICON_CONFIG: Record<EOrderType, ReactNode> = {
	[EOrderType.SINGLE]: <UserOutlined />,
	[EOrderType.GROUP]: <TeamOutlined />,
	[EOrderType.CORRECTION]: <SwapOutlined />,
};

const colorsByType: Record<EOrderType, Property.Color> = {
	[EOrderType.SINGLE]: 'default',
	[EOrderType.GROUP]: 'default',
	[EOrderType.CORRECTION]: 'orange',
};

export const OrderTypeTag: FC<IOrderTypeTagProps> = memo(({ type }) => {
	const { t: tCommon } = useTranslation('common');

	// !render
	return (
		<Tag
			icon={TAG_ICON_CONFIG[type]}
			style={{ ...DEFAULT_TAG_STYLES, fontSize: '14px' }}
			color={colorsByType[type]}
		>
			{tCommon(`order_type.${type}`)}
		</Tag>
	);
});
