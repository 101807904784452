import { Property } from 'csstype';
import { WithRequired } from 'types/common';
import { MapToken } from 'antd/lib/theme/interface';

export type TBrand = 'getdukan' | 'getcari';

export const DEFAULT_TOKENS: Partial<MapToken> = {
	fontFamily: 'Jost',
	colorText: '#3b424b',
};

interface IDeprecatedColors {
	//-- ANTD 4.0
	'--ant-primary-color': Property.Color;
	'--ant-primary-color-disabled': Property.Color;
	'--ant-primary-color-hover': Property.Color;
	'--ant-primary-color-active': Property.Color;
	'--ant-primary-color-outline': Property.Color;
	'--ant-primary-color-deprecated-bg': Property.Color;
	'--ant-primary-color-deprecated-border': Property.Color;
	'--ant-primary-1': Property.Color;
	'--ant-primary-2': Property.Color;
	'--ant-primary-3': Property.Color;
	'--ant-primary-4': Property.Color;
	'--ant-primary-5': Property.Color;
	'--ant-primary-6': Property.Color;
	'--ant-primary-7': Property.Color;
	'--ant-primary-8': Property.Color;
	'--ant-primary-9': Property.Color;
	'--ant-primary-10': Property.Color;
	'--ant-primary-color-deprecated-l-35': Property.Color;
	'--ant-primary-color-deprecated-l-20': Property.Color;
	'--ant-primary-color-deprecated-t-20': Property.Color;
	'--ant-primary-color-deprecated-t-50': Property.Color;
	'--ant-primary-color-deprecated-f-12': Property.Color;
	'--ant-primary-color-active-deprecated-f-30': Property.Color;
	'--ant-primary-color-active-deprecated-d-02': Property.Color;
	'--ant-warning-color': Property.Color;
	'--ant-warning-color-disabled': Property.Color;
	'--ant-warning-color-hover': Property.Color;
	'--ant-warning-color-active': Property.Color;
	'--ant-warning-color-outline': Property.Color;
	'--ant-warning-color-deprecated-bg': Property.Color;
	'--ant-warning-color-deprecated-border': Property.Color;
}

export const TOKEN_CONFIG_BY_BRAND: Record<
	TBrand,
	WithRequired<Partial<MapToken>, 'colorPrimary' | 'colorWarning'> & IDeprecatedColors
> = {
	getdukan: {
		colorPrimary: '#d81f51',
		colorWarning: '#f7be11',

		//-- ANTD 4.0
		'--ant-primary-color': '#d81f51',
		'--ant-primary-color-disabled': '#ffc7ce',
		'--ant-primary-color-hover': '#e6456a',
		'--ant-primary-color-active': '#b31041',
		'--ant-primary-color-outline': 'rgb(216 31 81 / 20%)',
		'--ant-primary-color-deprecated-bg': '#fff0f1',
		'--ant-primary-color-deprecated-border': '#ff9eae',
		'--ant-primary-1': '#fff0f1',
		'--ant-primary-2': '#ffc7ce',
		'--ant-primary-3': '#ff9eae',
		'--ant-primary-4': '#f26f8a',
		'--ant-primary-5': '#e6456a',
		'--ant-primary-6': '#d81f51',
		'--ant-primary-7': '#b31041',
		'--ant-primary-8': '#8c0632',
		'--ant-primary-9': '#660025',
		'--ant-primary-10': '#40001a',
		'--ant-primary-color-deprecated-l-35': '#f4b5c6',
		'--ant-primary-color-deprecated-l-20': '#eb7293',
		'--ant-primary-color-deprecated-t-20': '#e04c74',
		'--ant-primary-color-deprecated-t-50': '#ec8fa8',
		'--ant-primary-color-deprecated-f-12': 'rgba(216, 31, 81, 0.12)',
		'--ant-primary-color-active-deprecated-f-30': 'rgba(255, 240, 241, 0.3)',
		'--ant-primary-color-active-deprecated-d-02': '#ffe6e7',
		'--ant-warning-color': '#f7be11',
		'--ant-warning-color-disabled': '#fff6b5',
		'--ant-warning-color-hover': '#ffd43b',
		'--ant-warning-color-active': '#d19704',
		'--ant-warning-color-outline': 'rgba(247, 190, 17, 0.2)',
		'--ant-warning-color-deprecated-bg': '#fffde6',
		'--ant-warning-color-deprecated-border': '#ffee8c',
	},
	getcari: {
		colorPrimary: '#cc0071',
		colorWarning: '#ffae00',

		//-- ANTD 4.0
		'--ant-primary-color': 'rgb(204, 0, 113)',
		'--ant-primary-color-disabled': '#ffa3c9',
		'--ant-primary-color-hover': '#d92381',
		'--ant-primary-color-active': '#a60061',
		'--ant-primary-color-outline': 'rgba(204, 0, 113, 0.2)',
		'--ant-primary-color-deprecated-bg': '#ffe6ef',
		'--ant-primary-color-deprecated-border': '#f274ad',
		'--ant-primary-1': '#ffe6ef',
		'--ant-primary-2': '#ffa3c9',
		'--ant-primary-3': '#f274ad',
		'--ant-primary-4': '#e64995',
		'--ant-primary-5': '#d92381',
		'--ant-primary-6': '#cc0071',
		'--ant-primary-7': '#a60061',
		'--ant-primary-8': '#80004f',
		'--ant-primary-9': '#59003a',
		'--ant-primary-10': '#330023',
		'--ant-primary-color-deprecated-l-35': 'rgb(255, 128, 198)',
		'--ant-primary-color-deprecated-l-20': 'rgb(255, 51, 164)',
		'--ant-primary-color-deprecated-t-20': 'rgb(214, 51, 141)',
		'--ant-primary-color-deprecated-t-50': 'rgb(230, 128, 184)',
		'--ant-primary-color-deprecated-f-12': 'rgba(204, 0, 113, 0.12)',
		'--ant-primary-color-active-deprecated-f-30': 'rgba(255, 230, 239, 0.3)',
		'--ant-primary-color-active-deprecated-d-02': 'rgb(255, 220, 232)',
		'--ant-warning-color': 'rgb(255, 174, 0)',
		'--ant-warning-color-disabled': '#ffeea3',
		'--ant-warning-color-hover': '#ffc229',
		'--ant-warning-color-active': '#d98d00',
		'--ant-warning-color-outline': 'rgba(255, 174, 0, 0.2)',
		'--ant-warning-color-deprecated-bg': '#fffbe6',
		'--ant-warning-color-deprecated-border': '#ffe27a',
	},
};
