import { EOrderStatus } from 'types/api';
import { EDispatcherTab, IDispatcherTabConfig, TDispatcherTabs } from './types';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export const ORDERS_PER_PAGE = '60';

export const DATE_SORT_BUTTONS_CONFIG = [
	{ key: 'newest', icon: <ArrowDownOutlined /> },
	{ key: 'oldest', icon: <ArrowUpOutlined /> },
];

// ! tabs
export const getDispatcherTabKey = (key: string) => EDispatcherTab[key as TDispatcherTabs];

export const DISPATCHER_DEFAULT_ACTIVE_TAB = EDispatcherTab.NEW;

export const DISPATCHER_TAB_CONFIG_SET: Record<EDispatcherTab, IDispatcherTabConfig> = {
	[EDispatcherTab.SCHEDULED]: {
		key: EDispatcherTab.SCHEDULED,
		filter: {
			status: EOrderStatus.SCHEDULED,
		},
		extractMetricFn: (metrics) => metrics.scheduled,
	},
	[EDispatcherTab.NEW]: {
		key: EDispatcherTab.NEW,
		filter: {
			status: EOrderStatus.NEW,
		},
		extractMetricFn: (metrics) => metrics.new,
	},
	[EDispatcherTab.PREPARING]: {
		key: EDispatcherTab.PREPARING,
		filter: {
			status: [EOrderStatus.PREPARING],
		},
		extractMetricFn: (metrics) => metrics.preparing,
	},
	[EDispatcherTab.READY_FOR_PICK_UP]: {
		key: EDispatcherTab.READY_FOR_PICK_UP,
		filter: {
			status: [EOrderStatus.READY],
		},
		extractMetricFn: (metrics) => metrics.ready.total,
	},
	[EDispatcherTab.DISPATCHED]: {
		key: EDispatcherTab.DISPATCHED,
		filter: {
			status: [EOrderStatus.DISPATCHED],
		},
		extractMetricFn: (metrics) => metrics.dispatched.total,
	},
	[EDispatcherTab.COMPLETED]: {
		key: EDispatcherTab.COMPLETED,
		filter: {
			status: [EOrderStatus.COMPLETED],
		},
		extractMetricFn: (metrics) => metrics.completed,
	},
	[EDispatcherTab.CANCELLED]: {
		key: EDispatcherTab.CANCELLED,
		filter: {
			status: [EOrderStatus.CANCELLED, EOrderStatus.REJECTED],
		},
		extractMetricFn: (metrics) => metrics.interrupted.cancelled + metrics.interrupted.rejected,
	},
};

export const DISPATCHER_FILTER_TABS: EDispatcherTab[] = [
	EDispatcherTab.SCHEDULED,
	EDispatcherTab.NEW,
	EDispatcherTab.PREPARING,
	EDispatcherTab.READY_FOR_PICK_UP,
	EDispatcherTab.DISPATCHED,
	EDispatcherTab.COMPLETED,
	EDispatcherTab.CANCELLED,
];
