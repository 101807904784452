import { EBrand } from 'types/appConfig';
import { Nullable, TEmptyFunction } from 'types/common';

const LOCAL_STORAGE_KEY_PREFIX = 'vendor_web_dashboard';

const getKeyWithAppPrefix = (brand: EBrand, key: string) => `${brand}:${LOCAL_STORAGE_KEY_PREFIX}:${key}`;

export interface IStorageUtils {
	get: (key: string) => Nullable<string>;
	set: (key: string, value: string) => void;
	remove: (key: string) => void;
	clear: TEmptyFunction;
}

export const storageUtils = (brand: EBrand): IStorageUtils => ({
	get: (key: string): Nullable<string> => {
		const value = localStorage.getItem(getKeyWithAppPrefix(brand, key));

		if (!value) {
			return null;
		}

		return JSON.parse(value);
	},
	set: (key: string, value: string): void => {
		localStorage.setItem(getKeyWithAppPrefix(brand, key), JSON.stringify(value));
	},
	remove: (key: string): void => {
		localStorage.removeItem(getKeyWithAppPrefix(brand, key));
	},
	clear: (): void => {
		const keyPrefix = getKeyWithAppPrefix(brand, '');
		Object.keys(localStorage).forEach((key) => {
			if (key.includes(keyPrefix)) localStorage.removeItem(key);
		});
	},
});
