import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { USER_TYPES_TAG_COLORS, USER_TYPES_TAG_ICONS } from './configs';
import { EUserTypes } from 'types/common';
import { IOrderTimelineProps } from './types';
import styles from './OrderTimeline.module.css';
import { Empty, Space, Typography as T, Tag, Timeline, TimelineItemProps, Tooltip } from 'antd';
import { CheckCircleOutlined, ShoppingOutlined } from '@ant-design/icons';

interface IUserRoleTagProps {
	id: number;
	name: string;
	role?: EUserTypes;
}

const UserRoleTag: FC<IUserRoleTagProps> = memo(({ id, name, role }) => {
	const { t: tCommon } = useTranslation('common');

	let stringTag = `#${id}`;
	if (name) {
		stringTag += ` - ${name}`;
	}

	return (
		<Space size={0}>
			{role && (
				<Tag
					key='user-role-tag'
					icon={USER_TYPES_TAG_ICONS[role]}
					color={USER_TYPES_TAG_COLORS[role]}
					style={{ ...DEFAULT_TAG_STYLES, textTransform: 'capitalize' }}
				>
					{tCommon(`user_types.${role}`)}
				</Tag>
			)}
			<Tag
				key='user-name-tag'
				style={DEFAULT_TAG_STYLES}
			>
				{stringTag}
			</Tag>
		</Space>
	);
});

export const OrderTimeline: FC<IOrderTimelineProps> = memo(({ items }) => {
	const { t: tOrders } = useTranslation('orders');

	const timeLineItems: TimelineItemProps[] = useMemo(
		() =>
			items.map(({ lastItem, createdAt, comment, status, userId, userName, userRole }, index) => ({
				key: 'item-' + index,
				className: styles.timeline_item,
				label: (
					<>
						<Tooltip title={tOrders('order_details.tabs.timeline.tooltips.order_status')}>
							<Tag
								key='status-tag'
								icon={<ShoppingOutlined />}
								style={DEFAULT_TAG_STYLES}
							>
								{status}
							</Tag>
						</Tooltip>
					</>
				),
				color: lastItem ? '#ffbd44' : 'green',
				dot: lastItem ? <CheckCircleOutlined className={styles.last_item_icon} /> : null,
				children: (
					<>
						<T.Paragraph
							key={`${index}-user`}
							className={styles.user}
						>
							<UserRoleTag
								id={userId}
								name={userName}
								role={userRole}
							/>
						</T.Paragraph>
						<T.Paragraph
							key={`${index}-date`}
							className={styles.date}
						>
							{createdAt} {comment && `- ${comment}`}
						</T.Paragraph>
					</>
				),
			})),
		[items, tOrders]
	);

	// ! render
	return (
		<>
			{!timeLineItems.length && <Empty />}

			<Timeline
				mode='left'
				items={timeLineItems}
			/>
		</>
	);
});
