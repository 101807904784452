import { INotification } from './types';
import { NotificationInstance } from 'antd/es/notification/interface';
import notificationSound from 'assets/sounds/new_order_sound.mp3';

export const NOTIFICATION_TTL = 5000;
export const sound = new Audio(notificationSound);

export const onNotificationClickCallback =
	(noticationControler: NotificationInstance, notification: INotification) => () => {
		const notificationCallBack = notification.onClick;

		notificationCallBack && notificationCallBack();
		noticationControler.destroy(notification.key || notification.id.toString());
	};
