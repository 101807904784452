import i18next from 'i18next';
import { THttpRequestConfig } from 'hooks/useHttpClient';
import { IFetchOrderListRequestConfig, IUpdateOrdersStatusConfig } from 'types/api';
import { IBranchDispatcherOrderListFilterRequest } from 'pages/Dispatcher/types';

export const ORDERS_API = {
	fetchOrderList: ({
		branchIdList,
		statusList,
		start_date,
		end_date,
	}: IFetchOrderListRequestConfig): THttpRequestConfig => ({
		url: `/vendor/orders`,
		method: 'GET',
		params: { store_ids: branchIdList, status: statusList, start_date, end_date },
	}),
	dispatcherList: (
		branchIdList: number[],
		params?: Partial<IBranchDispatcherOrderListFilterRequest>,
		signal?: AbortSignal
	): THttpRequestConfig => ({
		url: `/vendor/orders/dispatcher`,
		method: 'GET',
		params: {
			store_ids: branchIdList,
			...params,
			signal,
		},
	}),
	dispatcherBadgesMetrics: (branchIdList: number[], start_date?: string): THttpRequestConfig => ({
		url: `/vendor/orders/dispatcher-badges`,
		method: 'GET',
		params: {
			start_date,
			store_ids: branchIdList,
		},
	}),
	get: (orderId: number, language: string = i18next.language): THttpRequestConfig => ({
		url: `/vendor/orders/${orderId}`,
		method: 'GET',
		headers: {
			accept_language: language,
		},
	}),
	patchStatus: (orderId: number, data: IUpdateOrdersStatusConfig): THttpRequestConfig => ({
		url: `/vendor/orders/${orderId}/status`,
		method: 'PATCH',
		data,
	}),
	getHistory: (orderId: number): THttpRequestConfig => ({
		url: `/vendor/orders/${orderId}/history`,
		method: 'GET',
	}),
};
