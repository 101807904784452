import { SUPPORTED_LANGUAGES } from 'configs/common';
import { createSelector } from '@reduxjs/toolkit';
import { ISettingsSlice } from 'store/slices/settings';
import { ISelector } from '../types';

export const getSettingsSlice: ISelector<void, ISettingsSlice> = (state) => state.settings;

export const getIsRTL = createSelector([getSettingsSlice], ({ language }) => {
	const languageConfig = SUPPORTED_LANGUAGES[language];
	return languageConfig?.defaultDirection === 'rtl';
});
