import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalConfigs } from 'hooks';
import { EPromotionType, IPromotionListResponse } from 'types/api';
import styles from './RecommendedCampaignDrawer.module.css';
import { Typography } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { TOKEN_CONFIG_BY_BRAND } from 'brand-config';

interface IDrawerHeaderProps {
	campaign: IPromotionListResponse;
}

const DrawerHeader: FC<IDrawerHeaderProps> = ({ campaign }) => {
	const { configs } = useGlobalConfigs();
	const { t: tCampaigns } = useTranslation('campaigns');

	const isRecommendation = campaign.configurations.type === EPromotionType.RECOMMENDATION;

	if (!isRecommendation) {
		return <Typography.Title level={4}>{tCampaigns('recommended.drawer_title')}</Typography.Title>;
	}

	return (
		<div
			className={styles.recommended_tag}
			style={{
				backgroundColor: TOKEN_CONFIG_BY_BRAND[configs.brand].colorPrimary,
			}}
		>
			<HeartOutlined size={16} />
			{tCampaigns('recommended.influencer', { influencer_name: campaign.authors.recommender?.name })}
		</div>
	);
};

export default DrawerHeader;
