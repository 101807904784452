import { FC, memo } from 'react';
import { getGoogleMapsExternalLink } from 'configs/common';
import { IAddressLinkBtnProps } from './types';
import LinkButton from 'components/LinkButton';

export const AddressLinkButton: FC<IAddressLinkBtnProps> = memo(({ icon, address, addressLatLng }) => {
	// ! render
	return (
		<LinkButton
			icon={icon}
			label={address}
			to={getGoogleMapsExternalLink(addressLatLng)}
			target='_blank'
		/>
	);
});
