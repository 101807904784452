export const LIST_DEFAULT_PARAMS = {
	page: '1',
	per_page: '10',
};

export * from './categories';
export * from './configurations';
export * from './countries';
export * from './menus';
export * from './metrics';
export * from './orders';
export * from './products';
export * from './branches';
export * from './workShifts';
export * from './vendor';
export * from './generalSettings';
export * from './invoices';

// store apis
export * from './auth';
