import { FC, ReactNode, createContext, useState } from 'react';
import { TEmptyFunction } from 'types/common';

interface IBranchDrawerContextData {
	openSelectBranchesDrawer: boolean;
	openDrawer: TEmptyFunction;
	closeDrawer: TEmptyFunction;
}

export const BranchDrawerContext = createContext<IBranchDrawerContextData>({} as IBranchDrawerContextData);

export const SelectBranchDrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [openSelectBranchesDrawer, setOpenSelectBranchesDrawer] = useState<boolean>(false);

	const openDrawer = () => setOpenSelectBranchesDrawer(true);
	const closeDrawer = () => setOpenSelectBranchesDrawer(false);

	const drawerData = { openSelectBranchesDrawer, openDrawer, closeDrawer };

	return <BranchDrawerContext.Provider value={drawerData}>{children}</BranchDrawerContext.Provider>;
};
