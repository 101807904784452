import { FC, PropsWithChildren, createContext, useEffect, useReducer, useRef } from 'react';
import { NOTIFICATION_TTL, onNotificationClickCallback, sound } from './configs';
import { Nullable } from 'types/common';
import {
	ENotificationActions,
	IAction,
	INotification,
	INotificationState,
	INotificationsProviderApi,
	NotificationType,
} from './types';
import { notification } from 'antd';

export const NotificationContext = createContext({} as INotificationsProviderApi);

// REDUCER
const reducerFunction =
	() =>
	(state: INotificationState, action: IAction): INotificationState => {
		switch (action.type) {
			case ENotificationActions.PUSH: {
				return {
					...state,
					notifications: [...state.notifications, { ...action.data, id: state.idCounter }],
					idCounter: state.idCounter + 1,
					newNotificationsCounter: state.newNotificationsCounter + 1,
				};
			}
			case ENotificationActions.POP: {
				return {
					...state,
					notifications: state.notifications.filter((curr) => curr.id !== action.data),
				};
			}
			case ENotificationActions.CLEAR: {
				return { ...state, notifications: [] };
			}
			case ENotificationActions.TOGGLE_MUTE: {
				return { ...state, mute: !state.mute };
			}
			case ENotificationActions.TOGGLE_DRAWER_OPEN: {
				return { ...state, drawerOpen: action.data ?? !state.drawerOpen, newNotificationsCounter: 0 };
			}
		}

		return state;
	};

// PROVIDER
export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
	const [state, dispatcher] = useReducer(reducerFunction(), {
		idCounter: 0,
		mute: false,
		notifications: [],
		drawerOpen: false,
		newNotificationsCounter: 0,
	});
	const lastNotificationIdShow = useRef<number>(0);

	// ! handlers
	const push = (newNotification: Omit<INotification, 'id'>) => {
		dispatcher({ type: ENotificationActions.PUSH, data: newNotification });
	};

	const deleteNotification = (id: number = 0) => {
		let deletedItem = state.notifications.find((curr) => curr.id === id);

		dispatcher({ type: ENotificationActions.POP, data: id });

		return deletedItem;
	};

	const pop = (): Nullable<INotification> => {
		if (!state.notifications.length) return null;

		let poppedItem = state.notifications[0];

		dispatcher({ type: ENotificationActions.POP, data: poppedItem.id });

		return poppedItem;
	};
	const toggleMute = () => {
		dispatcher({ type: ENotificationActions.TOGGLE_MUTE });
	};
	const toggleDrawer = (forceNewState?: boolean) => {
		notification.destroy();
		dispatcher({ type: ENotificationActions.TOGGLE_DRAWER_OPEN, data: forceNewState });
	};
	const clear = () => {
		dispatcher({ type: ENotificationActions.CLEAR });
	};
	const stopSound = () => {
		sound.pause();
	};

	const openNotification = (type: NotificationType | 'open') => notification[type];

	// ! useEffects
	useEffect(() => {
		if (state.idCounter > 0) {
			while (lastNotificationIdShow.current < state.idCounter) {
				const lastMessage = state.notifications.find((value) => value.id === lastNotificationIdShow.current);
				if (!lastMessage) {
					continue;
				}

				if (!state.mute) {
					sound.currentTime = 0;
					sound.loop = true;
					sound.play();
				}

				if (!state.drawerOpen) {
					const key = lastMessage.key || lastMessage.id.toString();

					openNotification(lastMessage.type || 'open')({
						key,
						btn: lastMessage.btn,
						message: lastMessage.title,
						description: lastMessage.description,
						onClick: onNotificationClickCallback(notification, lastMessage),
						style: lastMessage.onClick ? { cursor: 'pointer' } : {},
						duration: NOTIFICATION_TTL / 1000,
						className: 'disputed-orders-notification',
					});
				}

				lastNotificationIdShow.current++;
			}
		}
	}, [state]);

	useEffect(() => {
		if (state.mute) {
			sound.pause();
		}
	}, [state.mute]);

	// ! returns

	const notificationsProviderApi: INotificationsProviderApi = {
		state,
		push,
		pop,
		deleteNotification,
		clear,
		stopSound,
		toggleMute,
		toggleDrawer,
	};

	return <NotificationContext.Provider value={notificationsProviderApi}>{children}</NotificationContext.Provider>;
};
