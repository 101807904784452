import { FC, memo, useMemo } from 'react';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { PROMOTION_STATUS_TAG_COLORS } from './configs';
import { useLocaleTimeUtils } from 'hooks';
import { EPromotionStatus } from 'types/api/promotions';
import { IPromotionStatusTagProps, TFinalStatus } from './types';
import { TranslatedLabel } from 'components/TranslatedLabel/TranslatedLabel';
import dayjs from 'dayjs';
import { Tag } from 'antd';

const PromotionStatusTag: FC<IPromotionStatusTagProps> = memo(({ status, startDate }) => {
	const { getNowInTimeZone } = useLocaleTimeUtils();

	const finalStatus: TFinalStatus = useMemo(() => {
		if (status === EPromotionStatus.IN_PROGRESS && dayjs(startDate).isAfter(getNowInTimeZone())) {
			return 'scheduled';
		}

		return status;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, status]);

	// ! render
	return (
		<Tag
			color={PROMOTION_STATUS_TAG_COLORS[finalStatus]}
			style={{ ...DEFAULT_TAG_STYLES, textTransform: 'capitalize' }}
		>
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={finalStatus}
				extra={{ keyPrefix: 'enums.status' }}
			/>
		</Tag>
	);
});

export default PromotionStatusTag;
