import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useGlobalConfigs } from 'hooks';
import { EHttpStatus } from 'types/api';
import { AppError } from 'exceptions/AppError';
import { processClientLogout } from 'contexts/auth/helpers';
import { App } from 'antd';

export type THandleError = (error: AxiosResponse | AppError, displayErrorMsg?: boolean) => void;

const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong!';
const UNAUTHORIZED_ERROR_MESSAGE = 'Unauthorized';

const useHandleErrors = () => {
	const { storage } = useGlobalConfigs();
	const navigate = useNavigate();
	const { message } = App.useApp();

	const handleError: THandleError = (error: AxiosResponse | AppError, displayErrorMsg?: boolean) => {
		const status = error?.status;
		const errorMessage: string = error?.data?.message ?? DEFAULT_ERROR_MESSAGE;

		const isClientAppError = AppError.isError(error);
		const isUnauthorized = status === EHttpStatus.UNAUTHORIZED;

		// Access and refresh tokens were expired and this is not custom error
		if (isClientAppError || isUnauthorized) {
			const unauthorizedErrorMessage = isUnauthorized ? UNAUTHORIZED_ERROR_MESSAGE : errorMessage;
			processClientLogout(storage, navigate);

			message.error(unauthorizedErrorMessage);
			return;
		}

		if (displayErrorMsg) message.error(errorMessage, 5);

		console.warn('There was an error!', error);
	};

	return { handleError };
};

export { useHandleErrors };
