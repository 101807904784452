import { AxiosResponse } from 'axios';
import { Property } from 'csstype';
import { GENERAL_SETTINGS_API } from 'configs/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { THandleError } from 'utils/handleError';
import { TIntegrationType } from 'types/api';
import { EChoiceOption, Nullable } from 'types/common';
import { IDefaultStateFields } from '../types';
import { TIntegrationTabPageType } from 'pages/VendorManagement/IntegrationTabWrapper/types';
import { EVendorTabsKeys } from 'pages/VendorManagement/types';

export interface IFEIntegrationSetting {
	name: string;
	isIntegration: boolean;
	status_toggle_feature: boolean;
	color: Property.Color;
	tabs_access?: Record<TIntegrationTabPageType, boolean>;
	// allow integration to toggle between shifts source
	change_workshift_source_feature: boolean;
	// integration support for menu product snooze indicator feature
	menu_product_snooze_feature: boolean;
	manual_menu_sync: boolean;
}

export interface IBEIntegrationSetting {
	type: string;
	name: string;

	branch_status_toggle_feature: EChoiceOption;
	color: Property.Color;

	categories_access: EChoiceOption;
	menus_access: EChoiceOption;
	manual_menu_sync: EChoiceOption;
	products_access: EChoiceOption;
	working_shifts_access: EChoiceOption;
	// allow integration to toggle between shifts source
	change_workshift_source_feature: EChoiceOption;
	// integration support for menu product snooze indicator feature
	menu_product_snooze_feature: EChoiceOption;
}

const transformBEtoFE = (type: string, settings: IBEIntegrationSetting): IFEIntegrationSetting => {
	const tabsAccessFromBeFieldToTIntegrationTabPageType: Record<TIntegrationTabPageType, keyof IBEIntegrationSetting> =
		{
			[EVendorTabsKeys.CATEGORIES]: 'categories_access',
			[EVendorTabsKeys.MENUS]: 'menus_access',
			[EVendorTabsKeys.PRODUCTS]: 'products_access',
			[EVendorTabsKeys.WORKING_SHIFTS]: 'working_shifts_access',
		};

	const tabsAccess = Object.entries(tabsAccessFromBeFieldToTIntegrationTabPageType).reduce<
		Record<TIntegrationTabPageType, boolean>
	>(
		(acc, [tabFeKey, beKey]) => {
			const settingsAccessValue: boolean = settings[beKey] === EChoiceOption.YES;

			acc[tabFeKey as TIntegrationTabPageType] = settingsAccessValue;

			return acc;
		},
		{
			[EVendorTabsKeys.CATEGORIES]: true,
			[EVendorTabsKeys.MENUS]: true,
			[EVendorTabsKeys.PRODUCTS]: true,
			[EVendorTabsKeys.WORKING_SHIFTS]: true,
		}
	);

	const newSetting: IFEIntegrationSetting = {
		name: settings.name,
		isIntegration: type !== 'platform',
		status_toggle_feature: settings.branch_status_toggle_feature === EChoiceOption.YES,
		color: settings.color,
		tabs_access: tabsAccess,
		change_workshift_source_feature: settings.change_workshift_source_feature === EChoiceOption.YES,
		menu_product_snooze_feature: settings.menu_product_snooze_feature === EChoiceOption.YES,
		manual_menu_sync: settings.manual_menu_sync === EChoiceOption.YES,
	};

	return newSetting;
};

// ! initial state
export interface IIntegrationSlice extends IDefaultStateFields {
	data: Nullable<Record<TIntegrationType, IFEIntegrationSetting>>;
	error: Nullable<any>;
}

const integrationSliceInitialState: IIntegrationSlice = {
	data: null,
	error: null,
	// own state
	loading: false,
};

// ! thunks
export const fetchIntegrationSettings = createAsyncThunk<
	Record<string, IFEIntegrationSetting>,
	{
		http: THttpFunction;
		handleError: THandleError;
	},
	{ state: RootState }
>(
	'integrationSlice/fetchIntegrationSettings',
	async ({ http, handleError }, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<Record<TIntegrationType, IBEIntegrationSetting>> = await http(
				GENERAL_SETTINGS_API.integrationSettings()
			);

			const integrationRecordByType = Object.entries(response.data).reduce<
				Record<TIntegrationType, IFEIntegrationSetting>
			>((acc, [key, value]) => {
				acc[key] = transformBEtoFE(key, value);

				return acc;
			}, {});

			return integrationRecordByType;
		} catch (error) {
			handleError(error, true);
			return rejectWithValue(error);
		}
	},
	{
		condition: (args, { getState, extra }) => {
			const { integrations }: RootState = getState();

			if (integrations.data) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	}
);

const integrationSlice = createSlice({
	name: 'integrationSlice',
	initialState: integrationSliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchIntegrationSettings.pending, pendingCase)
			.addCase(fetchIntegrationSettings.rejected, (state, { payload }) => {
				state.error = payload;
				errorCase(state);
			})
			.addCase(fetchIntegrationSettings.fulfilled, (state, { payload }) => {
				state.data = payload;
				state.loading = false;
			});
	},
});

export default integrationSlice.reducer;
