import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { fetchOrderInformation } from 'store/slices/order';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import { IOrder } from 'types/api';
import ErrorPage from 'pages/ErrorPage';

interface IOrderContextData {
	orderId: number;
	data: IOrder;
	fetchData: () => Promise<void>;
}

const useOrderContext = () => {
	return useContext(OrderContext);
};

export default useOrderContext;

export const OrderContext = createContext<IOrderContextData>({} as IOrderContextData);

interface IOrderProviderProps {
	orderId?: number;
}

export const OrderProvider: FC<PropsWithChildren<IOrderProviderProps>> = ({ children, orderId: orderIdArg }) => {
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();
	const { orderId: parameterOrderId } = useParams();
	const dispatcher = useDispatch();

	// ! selectors
	const orderData = useSelector((state) => state.order.data);
	const error = useSelector((state) => state.order.error);

	const parsedParameterOrderId: number | undefined = parameterOrderId ? Number.parseInt(parameterOrderId) : undefined;
	const orderId: undefined | number = useMemo(
		() => orderIdArg ?? parsedParameterOrderId,
		[orderIdArg, parsedParameterOrderId]
	);

	const fetchData = async (orderId: number) => {
		dispatcher(fetchOrderInformation({ http, orderId, handleError }));
	};

	useEffect(() => {
		if (orderId) fetchData(orderId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId]);

	// ! render
	if (error) {
		return (
			<ErrorPage
				status={error.status}
				title={error.status}
				subTitle={error.data?.message}
			/>
		);
	}

	if (!orderId || !orderData || isNaN(orderId)) return null;

	const providerData: IOrderContextData = {
		orderId,
		data: orderData,
		fetchData: () => fetchData(orderId),
	};

	return <OrderContext.Provider value={providerData}>{children} </OrderContext.Provider>;
};
