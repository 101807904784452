import { FC, PropsWithChildren, useState } from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { useAuth } from 'hooks';
import useLogos from 'hooks/useLogos';
import styles from './Login.module.css';
import stylesNew from './LoginNew.module.css';
import loginSideImage from './login.svg';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const platformTitle = "Welcome to the Vendor's Dashboard!";

const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
	const { fullLogo } = useLogos();
	const currentYear = new Date().getFullYear();

	// ! render
	return (
		<div className={stylesNew.layout}>
			<div className={stylesNew.logo_container}>
				<img
					alt='logo'
					loading='lazy'
					src={fullLogo}
					className={stylesNew.logo}
				/>
			</div>
			{children}
			<div className={stylesNew.footer_container}>
				<div className={stylesNew.copyright}>
					Copyright © {currentYear} Cari General Trading Company. All rights reserved.
				</div>
				<div className={stylesNew.footer_logo_container} />
				<div className={stylesNew.footer_background_container} />
			</div>
		</div>
	);
};

const LoginPage: FC = () => {
	const auth = useAuth();

	// ! states
	const [signinLoading, setSigninLoading] = useState(false);

	// ! handlers
	const handleLogin = ({ email = '', password = '' }) => {
		setSigninLoading(true);
		auth.signin(email, password).catch(() => setSigninLoading(false));
	};

	// ! render
	if (auth.isAuthenticated()) {
		return <Navigate to={generatePath(ABSOLUTE_ROUTES.DASHBOARD)} />;
	}

	return (
		<Row
			wrap
			gutter={[124, 124]}
		>
			<Col
				xs={0}
				sm={0}
				md={0}
				lg={12}
			>
				<div className='flex flex-center'>
					<img
						src={loginSideImage}
						alt='login-icon'
						width={400}
						className={styles.img}
					/>
				</div>
			</Col>
			<Col>
				<Form
					layout='vertical'
					name='auth_form'
					className={styles.auth_form}
					validateTrigger='onBlur'
					onFinish={handleLogin}
				>
					<Form.Item>
						<Space
							direction='vertical'
							size={10}
						>
							<h2 className={stylesNew.title}>{platformTitle}</h2>
						</Space>
					</Form.Item>
					<Form.Item
						name='email'
						label='Email'
						rules={[
							{ required: true, message: 'Please input your email.' },
							{ type: 'email', message: 'Please input a valid email.' },
						]}
					>
						<Input
							size='large'
							autoComplete='off'
							placeholder=' Email'
							prefix={<UserOutlined />}
						/>
					</Form.Item>
					<Form.Item
						name='password'
						label='Password'
						rules={[{ required: true, message: 'Please input your password.' }]}
					>
						<Input.Password
							size='large'
							autoComplete='off'
							placeholder='Password'
							prefix={<LockOutlined className='site-form-item-icon' />}
						/>
					</Form.Item>
					{/* <Form.Item>
						<Link
							className={styles.forgot_password}
							onClick={() => {
								message.warning('Contact the operations team to reset your password', 5);
							}}
						>
							Forgot password?
						</Link>
					</Form.Item> */}
					<Form.Item>
						<Button
							block
							size='large'
							type='primary'
							htmlType='submit'
							loading={signinLoading}
							disabled={signinLoading}
						>
							Log in
						</Button>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
};

export const Login: FC = () => (
	<LoginLayout>
		<LoginPage />
	</LoginLayout>
);
