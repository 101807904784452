export interface IDashboardMetrics {
	summary: IDashboardMetricsSummary;
	metrics_per_branch: IDashboardMetricsBranchItem[];
	avg_delivery_time_per_branch: IDashboardMetricsAvgDeliveryTimeItem[];
	payment_usage_type_summary: IDashboardMetricsPaymentUsageTypeSummaryItem[];
	order_placement_ratio: IDashboardMetricsOrderPlacementRatioItem[];
	customer_acquisition_ratio: IDashboardMetricsCustomerAcquisitionRatioItem[];
}

interface IOrdersMetricsSummary {
	quantity: number;
	gross: number;
	currency_code: string;
}

export interface IRefundedOrdersMetricsSummary extends IOrdersMetricsSummary {
	gross_by_vendor: number;
	gross_by_platform: number;
}

export interface IDashboardMetricsSummary {
	global: IOrdersMetricsSummary;
	canceled_orders: IOrdersMetricsSummary;
	completed_orders: IOrdersMetricsSummary;
	refunded_orders: IRefundedOrdersMetricsSummary;
	rejected_orders: IOrdersMetricsSummary;
	total_new_customers: number;
}

export interface IDashboardMetricsBranchItem {
	branch_name: string;
	no_of_completed_orders: number;
	no_of_cancelled_orders: number;
	no_of_refunds: number;
	customers_acquired_by_vendor: number;
	customers_acquired_by_platform: number;
	gross_amount: string;
	store_discounts: string;
	first_time_order_fee: string;
	electronic_payment_fee: string;
	total_platform_delivery_fee: string;
}

export interface IDashboardMetricsAvgDeliveryTimeItem {
	branch_name: string;
	avg_delivery_time_in_mins: number;
}

export interface IDashboardMetricsPaymentUsageTypeSummaryItem {
	type: IDashboardPaymentMethods;
	usage_count: number;
}

export interface IDashboardMetricsOrderPlacementRatioItem {
	type: 'new_customer' | 'returning_customer';
	no_of_orders_placed: number;
	percentage_share: number;
}

export interface IDashboardMetricsCustomerAcquisitionRatioItem {
	source: IAcquisitionChannel;
	no_of_customers_acquired: number;
	percentage_share: number;
}

// ! enums
export enum IDashboardPaymentMethods {
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	KNET = 'knet',
	WALLET = 'wallet',
	MADA = 'mada',
	APPLE_PAY = 'apple_pay',
	BANK_ACCOUNT = 'bank_account',
	UAE_CC = 'uae_cc',
	PROMOCODE = 'promocode',
}

enum IAcquisitionChannel {
	PLATFORM = 'platform',
	VENDOR = 'vendor',
}
