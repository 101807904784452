import { Dispatch, FC, ReactNode, SetStateAction, createContext } from 'react';
import { useAuth } from 'hooks';
import { Spinner } from 'components/Spinner/Spinner';
import { IBranchUserAuthInfo } from './auth';

interface IAuthContextData {
	user: IBranchUserAuthInfo;
	selectedBranchIdList: number[];
	setSelectedBranchIdList: Dispatch<SetStateAction<number[]>>;
	selectedVendorIdList: number[];
	setSelectedVendorIdList: Dispatch<SetStateAction<number[]>>;
}

export const AuthenticatedInformationContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthenticatedInformationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	const auth = useAuth();

	if (!auth.user || !auth.selectedBranchIdList) return <Spinner />;

	const authData = {
		user: auth.user,
		selectedBranchIdList: auth.selectedBranchIdList,
		setSelectedBranchIdList: auth.setSelectedBranchIdList,
		selectedVendorIdList: auth.selectedVendorIdList,
		setSelectedVendorIdList: auth.setSelectedVendorIdList,
	};

	return (
		<AuthenticatedInformationContext.Provider value={authData}>{children}</AuthenticatedInformationContext.Provider>
	);
};
