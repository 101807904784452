import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDrawerHeaderProps } from './types';
import PromotionStatusTag from '../../PromotionUsageStatusTag/PromotionUsageStatusTag';
import { Flex, Typography } from 'antd';

const DrawerHeader: FC<IDrawerHeaderProps> = ({ status, startDate }) => {
	const { t: tCampaigns } = useTranslation('campaigns');
	return (
		<Flex
			align='center'
			gap={16}
		>
			<Typography.Title
				level={3}
				style={{ marginBottom: 0 }}
			>
				{tCampaigns('view.title')}
			</Typography.Title>
			<PromotionStatusTag
				status={status}
				startDate={startDate}
			/>
		</Flex>
	);
};

export default DrawerHeader;
