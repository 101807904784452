import { FC, memo } from 'react';
import { PRODUCT_DETAILS_COLUMNS } from '../configs';
import { IProductDetailsSummaryProps } from './types';
import styles from './ProductDetailsSummary.module.css';
import { Space, Table } from 'antd';

const { Summary } = Table;
const { Row, Cell } = Summary;

export const ProductDetailsSummary: FC<IProductDetailsSummaryProps> = memo(({ summary }) => (
	<Summary fixed>
		{summary.map(({ label, value, extraInfo }, index) => (
			<Row
				key={index}
				className={index === summary.length - 1 ? styles.last_summary_row : undefined}
			>
				<Cell
					index={0}
					align='right'
					colSpan={PRODUCT_DETAILS_COLUMNS.length - 1}
				>
					<Space
						size={0}
						direction='vertical'
					>
						{label}
						{extraInfo && <span className={styles.extra_info}>{extraInfo}</span>}
					</Space>
				</Cell>
				<Cell index={5}>{value}</Cell>
			</Row>
		))}
	</Summary>
));
