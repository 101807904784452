import { FC } from 'react';
import { FormSectionLabels } from 'components/FormSectionLabels';
import { CardRadioGroup } from './CardRadioGroup/CardRadioGroup';
import { ICardRadioGroupProps } from './CardRadioGroup/types';
import { Form, FormItemProps, Input, Space } from 'antd';

export const FormCardRadioGroup: FC<FormItemProps & ICardRadioGroupProps> = ({ name, label, extra, ...props }) => {
	return (
		<div>
			<Form.Item
				name={name}
				hidden
			>
				<Input />
			</Form.Item>
			<Space
				size={16}
				direction='vertical'
				className='w-100'
			>
				<FormSectionLabels
					label={label}
					extra={extra}
				/>
				<CardRadioGroup {...props} />
			</Space>
		</div>
	);
};
