import { ReactNode } from 'react';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EOrderBookingTypes, IDispatcherOrder } from 'types/api';
import { TEmptyFunction } from 'types/common';
import { INotification } from 'contexts/notifications/types';
import TranslatedLabel from 'components/TranslatedLabel';
import { Button, Space, Tag } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

const TAGS_BY_BOOKING_TYPE: Record<EOrderBookingTypes, ReactNode> = {
	[EOrderBookingTypes.INSTANT]: (
		<Tag
			color='geekblue'
			style={DEFAULT_TAG_STYLES}
		>
			<TranslatedLabel i18nKey='order.booking_types.instant' />
		</Tag>
	),
	[EOrderBookingTypes.SCHEDULED]: (
		<Tag
			color='orange'
			style={DEFAULT_TAG_STYLES}
		>
			<Space>
				<CalendarOutlined />
				<TranslatedLabel i18nKey='order.booking_types.scheduled' />
			</Space>
		</Tag>
	),
};

export const generateNewOrderNotification = (
	order: IDispatcherOrder,
	onClickAction: TEmptyFunction
): Omit<INotification, 'id'> => {
	const isScheduleOrder = order.info.booking_type === EOrderBookingTypes.SCHEDULED && !order.info.can_be_prepared;
	return {
		title: (
			<Space size='middle'>
				<TranslatedLabel
					i18nKey='new_order_title'
					nameSpace='notifications'
				/>

				{TAGS_BY_BOOKING_TYPE[order.info.booking_type]}
			</Space>
		),
		description: (
			<Space wrap>
				{isScheduleOrder && (
					<TranslatedLabel
						nameSpace='notifications'
						i18nKey='description.schedule'
					/>
				)}

				<TranslatedLabel
					nameSpace='notifications'
					i18nKey='description.order'
				/>

				<span className='link_button_no_width'>
					<Button
						type='link'
						className='link_button'
						onClick={onClickAction}
					>
						{`#${order.info.code}`}
					</Button>
				</span>

				<TranslatedLabel
					nameSpace='notifications'
					i18nKey='description.end_sentence'
				/>
			</Space>
		),
		btn: (
			<Button
				type='default'
				size='small'
				onClick={onClickAction}
			>
				<TranslatedLabel
					i18nKey='action_buttons.open_order_details'
					nameSpace='notifications'
				/>
			</Button>
		),
		onClick: onClickAction,
	};
};
