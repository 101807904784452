import { transformToBEAcceptanceFormat } from 'utils/date';
import dayjs from 'dayjs';

export const preserveUndefinedDateWhenTransformToBEAcceptanceFormat = (
	date?: string | Date | dayjs.Dayjs
): string | undefined => {
	if (date === undefined) return undefined;

	return transformToBEAcceptanceFormat(date);
};
