import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { useGlobalConfigs } from 'hooks';
import { EBrand } from 'types/appConfig';
import { App, ConfigProvider } from 'antd';
import { DEFAULT_TOKENS, TOKEN_CONFIG_BY_BRAND } from 'brand-config';

export interface IAppBrandNameData {
	brand: EBrand;
}

const setFavIcon = (brandName: string = '') => {
	var link: any = document.querySelector("link[rel~='icon']");
	if (!link) {
		link = document.createElement('link');
		link.rel = 'icon';
		document.head.appendChild(link);
	}
	link.href = `/${brandName}-favicon.ico`;
};
const useAppBrand = () => {
	return useContext(AppBrandContext);
};

export default useAppBrand;

export const AppBrandContext = createContext<IAppBrandNameData>({} as IAppBrandNameData);

export const AppBrandProvider = ({ children }: PropsWithChildren) => {
	const { configs } = useGlobalConfigs();

	const theme = useMemo(
		() => ({
			components: {
				Button: {
					primaryShadow: 'none',
				},
			},
			token: {
				...DEFAULT_TOKENS,
				...TOKEN_CONFIG_BY_BRAND[configs.brand],
				colorLinkHover: TOKEN_CONFIG_BY_BRAND[configs.brand].colorPrimary,
				colorLinkActive: TOKEN_CONFIG_BY_BRAND[configs.brand]['--ant-primary-2'],
			},
		}),
		[configs.brand]
	);

	useEffect(() => {
		if (!configs.brand) return;

		setFavIcon(configs.brand);

		const brandTokenConfig = TOKEN_CONFIG_BY_BRAND[configs.brand as EBrand];

		// TODO: update all css with new colors and remove this antd 4.xx colors hack
		Object.entries(brandTokenConfig)
			.filter(([key, value]) => key.startsWith('--'))
			.forEach(([key, value]) => {
				document.documentElement.style.setProperty(key, value);
			});
	}, [configs.brand]);

	if (!configs.brand || !theme) return null;

	const brandData: IAppBrandNameData = {
		brand: configs.brand,
	};

	return (
		<AppBrandContext.Provider value={brandData}>
			<ConfigProvider theme={theme}>
				<App>{children}</App>
			</ConfigProvider>
		</AppBrandContext.Provider>
	);
};
