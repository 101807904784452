import { useTranslation } from 'react-i18next';
import { debounce } from 'utils/debounce';
import { Input } from 'antd';

interface IInputSearchVendorsProps {
	handleVendorSearch: (value: string) => void;
}
export const InputSearchVendors = ({ handleVendorSearch }: IInputSearchVendorsProps) => {
	const { t: tCommon } = useTranslation('common');
	const debouncedHandleVendorSearch = debounce(handleVendorSearch, 400);

	return (
		<Input.Search
			allowClear
			style={{ display: 'block', margin: 'auto' }}
			placeholder={tCommon('nav_items.placeholders.search_vendors')}
			onSearch={handleVendorSearch}
			onChange={(e) => debouncedHandleVendorSearch(e.target.value)}
		/>
	);
};
