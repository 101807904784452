import { PROMOTION_TABLE_COLUMNS } from './configs';
import { PROMOTION_CAMPAIGN_LIST_TABLE } from './configs.campaigns';
import { IPromotionTableData } from './types';
import { FilterValue } from 'antd/es/table/interface';
import { ColumnType, ColumnsType } from 'antd/lib/table';

// ! ADD FILTERED VALUE
interface IAddFilteredValueToColumnArgs {
	columnItem: ColumnType<IPromotionTableData>;
	searchParams: URLSearchParams;
}

const addFilteredValueToColumn = ({
	columnItem,
	searchParams,
}: IAddFilteredValueToColumnArgs): ColumnType<IPromotionTableData> => {
	if (columnItem.key === 'vendors_id') {
		const vendorsIds = searchParams.getAll('vendors_id');
		const filteredValue: FilterValue = vendorsIds.map((vendorId) => Number.parseInt(vendorId));

		return {
			...columnItem,
			filteredValue,
		};
	}

	return columnItem;
};

// ! DEV NOTE: Prevent antd persisting filter status bug between multiple renders
export const generatePromotionListTableColumns = (
	columnsToBeRendered = PROMOTION_CAMPAIGN_LIST_TABLE,
	searchParams: URLSearchParams
): ColumnsType<IPromotionTableData> => {
	return columnsToBeRendered.map((column) => {
		const columnItem = {
			...PROMOTION_TABLE_COLUMNS[column as keyof typeof PROMOTION_TABLE_COLUMNS],
			key: PROMOTION_TABLE_COLUMNS[column as keyof typeof PROMOTION_TABLE_COLUMNS].key ?? column,
			ellipsis: true,
		};

		return addFilteredValueToColumn({ columnItem, searchParams });
	});
};
