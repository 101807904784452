import { initReactI18next } from 'react-i18next';
import i18next, { ReactOptions } from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { APP_LANGUAGE_STORAGE_KEY } from 'configs/common';
import { ESupportedCountryCodes } from 'types/common';

i18next
	.use(initReactI18next)
	.use(Backend)
	.use(LanguageDetector)
	.init<HttpBackendOptions & DetectorOptions & ReactOptions>({
		react: {
			useSuspense: true,
		},
		backend: {
			loadPath: `/locales/{{ns}}/{{ns}}.{{lng}}.json`,
			queryStringParams: { v: process.env.REACT_APP_BUILD_NUMBER ?? crypto.randomUUID() },
		},
		debug: false, // process.env.NODE_ENV === 'development',
		lng: localStorage.getItem(APP_LANGUAGE_STORAGE_KEY) || ESupportedCountryCodes.ENGLISH,
		returnEmptyString: false,
		returnNull: false, // ensure to update this value in "src/i18n-declaration.d.ts" before making changes.
		ns: ['common', 'branches', 'notifications'],
		defaultNS: 'common',
		detection: {
			// order and from where user language should be detected
			order: ['localStorage', 'cookie'],
			// keys or params to lookup language from
			lookupCookie: 'i18nextLng',
			lookupQuerystring: 'i18nextLng',
			// cache user language on
			caches: ['localStorage', 'cookie'],
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18next;
