import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { useAuthenticatedInformation } from 'hooks';
import { EVendorUserType, IOrderBranch } from 'types/api';
import LinkButton from 'components/LinkButton';
import { ShopOutlined } from '@ant-design/icons';

interface IBranchLinkProps {
	id: IOrderBranch['id'];
	name: IOrderBranch['name'];
	vendor_id: IOrderBranch['vendor_id'];
}

const BranchLink: FC<IBranchLinkProps> = ({ id, vendor_id, name }) => {
	const { user } = useAuthenticatedInformation();

	const branchUrlConfig: Record<EVendorUserType, string> = {
		[EVendorUserType.VENDOR_MANAGER]: ABSOLUTE_ROUTES.VENDOR_MANAGEMENT_BRANCH_ID_TAB_DETAILS,
		[EVendorUserType.BRANCH_MANAGER]: ABSOLUTE_ROUTES.BRANCH_DETAILS,
		[EVendorUserType.FINANCE_MANAGER]: '',
	};

	return (
		<LinkButton
			icon={<ShopOutlined />}
			label={name}
			to={generatePath(branchUrlConfig[user?.type], {
				vendorId: vendor_id,
				branchId: id,
			})}
		/>
	);
};

export default BranchLink;
