import { THttpRequestConfig, THttpRequestParams } from 'hooks/useHttpClient';

export const VENDOR_METRICS_API = {
	getEarningsConfig: (store_ids?: number[], params?: THttpRequestParams): THttpRequestConfig => {
		return {
			url: `vendor/metrics/earnings`,
			method: 'POST',
			params: {
				...params,
			},
			data: {
				store_ids,
			},
		};
	},
	getEarningsCSVConfig: (store_ids?: number[], params: THttpRequestParams = {}): THttpRequestConfig => {
		delete params.per_page;
		delete params.page;

		return {
			url: `vendor/metrics/earnings/export`,
			method: 'POST',
			params: {
				...params,
			},
			data: {
				store_ids,
			},
		};
	},

	fetchDashboardMetrics: (store_ids: number[], params: THttpRequestParams): THttpRequestConfig => ({
		url: `vendor/metrics/dashboard`,
		method: 'POST',
		params: {
			...params,
		},
		data: {
			store_ids,
		},
	}),

	getCancelledOrdersConfig: (store_ids?: number[], params?: THttpRequestParams): THttpRequestConfig => ({
		url: `vendor/metrics/cancelled-orders`,
		method: 'POST',
		params: {
			...params,
		},
		data: {
			store_ids,
		},
	}),

	getCancelledOrdersCSVConfig: (store_ids?: number[], params: THttpRequestParams = {}): THttpRequestConfig => {
		delete params.per_page;
		delete params.page;

		return {
			url: `vendor/metrics/cancelled-orders/export`,
			method: 'POST',
			params: {
				...params,
			},
			data: {
				store_ids,
			},
		};
	},
};
