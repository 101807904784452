import { AxiosRequestConfig } from 'axios';
import { THttpRequestConfig } from 'hooks/useHttpClient';

export const AUTH_TOKEN_KEY = 'auth_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const REFRESH_TOKEN_URL = 'vendor/auth/token';

export const AUTH_API = {
	logout: (): THttpRequestConfig => ({
		url: 'vendor/auth/logout',
		method: 'POST',
	}),
	profile: (): THttpRequestConfig => ({
		url: 'vendor/profile',
		method: 'GET',
	}),
	login: (email: string, password: string): THttpRequestConfig => ({
		url: 'vendor/auth/login',
		method: 'POST',
		data: {
			email,
			password,
		},
	}),
	refreshToken: (refresh_token: string): THttpRequestConfig => ({
		url: REFRESH_TOKEN_URL,
		method: 'POST',
		data: {
			refresh_token,
		},
	}),
	changePassword: (old_password: string, password: string): THttpRequestConfig => ({
		url: 'vendor/auth/change-password',
		method: 'POST',
		data: {
			old_password,
			password,
		},
	}),
	getAccessibleVendors: (params?: AxiosRequestConfig['params']): THttpRequestConfig => ({
		url: '/vendor/accessible-vendors',
		method: 'GET',
		params,
	}),
	getAccessibleStores: (search?: string, params?: AxiosRequestConfig['params']): THttpRequestConfig => ({
		url: '/vendor/accessible-stores',
		method: 'GET',
		params: {
			search,
			...params,
		},
	}),
};
