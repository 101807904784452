import { ReactNode } from 'react';
import { Nullable, TEmptyFunction } from 'types/common';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface INotification {
	id: number;
	description: ReactNode;
	key?: string;
	title?: ReactNode;
	onClick?: TEmptyFunction;
	btn?: ReactNode;
	type?: NotificationType;
}

export interface INotificationsProviderApi {
	state: INotificationState;
	push: (newNotification: Omit<INotification, 'id'>) => void;
	pop: () => Nullable<INotification>;
	deleteNotification: (index?: number) => INotification | undefined;
	clear: TEmptyFunction;

	// Sound controls
	stopSound: TEmptyFunction;
	toggleMute: TEmptyFunction;
	toggleDrawer: (forceNewState?: boolean) => void;
}

export enum ENotificationActions {
	PUSH,
	POP,
	TOGGLE_MUTE,
	TOGGLE_DRAWER_OPEN,
	CLEAR,
}

export interface IAction {
	type: ENotificationActions;
	data?: any;
}

export interface INotificationState {
	mute: boolean;
	idCounter: number;
	drawerOpen: boolean;
	notifications: INotification[];
	newNotificationsCounter: number;
}
