import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'hooks';
import { Badge, Tooltip } from 'antd';
import { BellOutlined } from '@ant-design/icons';

export const NotificationDrawerOpener: FC = () => {
	const { state, toggleDrawer } = useNotifications();
	const { t: tNotifications } = useTranslation('notifications');

	return (
		<Tooltip title={tNotifications('title')}>
			<Badge
				count={state.newNotificationsCounter}
				overflowCount={9}
			>
				<BellOutlined
					onClick={() => toggleDrawer()}
					style={{ fontSize: '24px' }}
				></BellOutlined>
			</Badge>
		</Tooltip>
	);
};
