import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ORDERS_API } from 'configs/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { THandleError } from 'utils/handleError';
import { IOrder } from 'types/api';
import { Nullable } from 'types/common';
import { IDefaultStateFields } from '../types';

// ! initial state
interface IOrderSlice extends IDefaultStateFields {
	data: Nullable<IOrder>;
	error: Nullable<any>;
}

const SliceInitialState: IOrderSlice = {
	data: null,
	error: null,
	loading: false,
};

export const fetchOrderInformation = createAsyncThunk<
	IOrder,
	{
		http: THttpFunction;
		orderId: number;
		handleError: THandleError;
		signal?: AbortSignal;
	},
	{ state: RootState }
>(
	'orderSlice/fetchOrder',
	async ({ http, orderId, handleError, signal }, { rejectWithValue }) => {
		if (orderId === undefined) return rejectWithValue(orderId);

		try {
			const requestConfig: AxiosRequestConfig = ORDERS_API.get(orderId);
			requestConfig.signal = signal;
			const prom: AxiosResponse<IOrder> = await http(requestConfig);

			return prom.data;
		} catch (error) {
			handleError(error, true);
			return rejectWithValue(error);
		}
	},
	{
		condition: (_, { getState }) => {
			const { order } = getState();

			if (order.loading) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	}
);

// ! slice
const orderSlice = createSlice({
	name: 'orderSlice',
	initialState: SliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrderInformation.pending, (state, { payload }) => {
				state.error = null;
				pendingCase(state);
			})
			.addCase(fetchOrderInformation.rejected, (state, { payload }) => {
				state.error = payload;
				errorCase(state);
			})
			.addCase(fetchOrderInformation.fulfilled, (state, { payload }) => {
				state.data = payload;
				state.loading = false;
			});
	},
});

export default orderSlice.reducer;
