import { IMenu } from 'types/api';
import { EChoiceOption, EStatus, Nullable } from 'types/common';

// ! enums

export enum ENewProductDiscountType {
	NONE = 'none',
	AMOUNT = 'amount',
	PERCENTAGE = 'percentage',
}

// ! interfaces
interface IStatus {
	status: EStatus;
}

export interface IOrdered {
	sort_order: number;
}

export interface IProduct extends IStatus {
	id: number;
	name: string;
	name_ar: string;
	description?: string;
	description_ar?: string;
	image: string;
	price: number;
	discount_value?: number;
	discount_type?: ENewProductDiscountType;
	maximum_order_quantity: number;

	variants_group_name: string;
	variants_group_name_ar: string;
	variants: IVariant[];

	// associated menus
	menus: Pick<IMenu, 'id' | 'name'>[];

	actions: {
		can_modify_product_max_order_quantity: boolean;
		can_modify_product_variant_barcode: boolean;
		can_modify_product_variant_sku: boolean;
	};
}

export interface IProductListItemResponse {
	id: IProduct['id'];
	image: IProduct['image'];
	name: IProduct['name'];
	name_ar: IProduct['name_ar'];
	price: IProduct['price'];
	menus: IProduct['menus'];
	status: IProduct['status'];
}

export interface IVariant extends IOrdered, IStatus {
	id?: number;
	name: string;
	name_ar: string;
	price: number;
	default: EChoiceOption;
	sku: Nullable<string>;
	barcode: Nullable<string>;

	option_groups: IOptionGroup[];
}

export interface IOptionGroup extends IOrdered, IStatus {
	id?: number;
	name: string;
	name_ar: string;
	instructions: string;
	instructions_ar: string;
	limit_min: number;
	limit_max: number;
	show_selection_limitation_label: EChoiceOption;

	options: IOption[];
}
export interface IOption extends IOrdered, IStatus {
	id?: number;
	name: string;
	name_ar: string;
	price: number;
}

// ! CRUD

// * Create / Update
export interface IProductDetailsPayload
	extends Pick<IProduct, 'name' | 'name_ar' | 'description' | 'description_ar' | 'discount_type' | 'discount_value'> {
	sub_category_id: number;
	price?: number; // ! Only sent on product create
}

// * Variants - Bulk Save
export interface IVariantBulkSavePayload {
	variants_group_name: string;
	variants_group_name_ar: string;
	variants: IVariant[];
}

// * Options - Bulk Save
export interface IOptionGroupsSavePayload {
	variants: Pick<IVariant, 'option_groups' | 'id'>[];
}

// ! CSV ----------------------
// * Products and Variants
// unSerialized file interfaces
export interface IParsedCsvProductItem
	extends Omit<ICsvProductItem, 'product_variants' | 'row_number'>,
		Omit<ICsvProductItemVariant, 'row_number'> {}

export interface IParsedCsvProductRowItem extends IParsedCsvProductItem {
	row_number: number;
}

// serialized interfaces//
interface ICsvProductItemVariant {
	variant_id?: number;
	variant_name: string;
	variant_name_ar: string;
	variant_price: number;
	variant_status: EStatus;
	variant_barcode: string;
	variant_sku: string;
	row_number: number;
}

interface ICsvProductItem {
	sub_category_id: number; // removed category_id from here
	product_id?: number;
	product_name: string;
	product_name_ar: string;
	product_price: number;
	product_description?: string;
	product_description_ar?: string;
	product_status: EStatus;
	product_maximum_order_quantity: number;
	row_number: number;
	product_variants: ICsvProductItemVariant[];
}

// * Option Groups and Options
export interface IParsedCsvOptionGroupAndOptionsItem
	extends Omit<ICsvOptionGroupItem, 'product_options' | 'row_number'>,
		Omit<ICsvOptionGroupOptionItem, 'row_number'> {}

export interface IParsedCsvOptionGroupAndOptionsRowItem extends IParsedCsvOptionGroupAndOptionsItem {
	row_number: number;
}
// serialized file interfaces
interface ICsvOptionGroupOptionItem {
	option_id?: number;
	option_name: string;
	option_name_ar: string;
	option_price: number;
	option_sort_order: number;
	option_status: EStatus;
	row_number: number;
}
export interface ICsvOptionGroupItem {
	option_group_id?: number;
	product_id: number;
	product_name: string;
	variant_id: number;
	variant_name: string;
	option_group_name: string;
	option_group_name_ar: string;
	option_group_instructions: string;
	option_group_instructions_ar: string;
	option_group_limit_min: number;
	option_group_limit_max: number;
	option_group_show_selection_limitation_label: EChoiceOption;
	option_group_sort_order: number;
	option_group_status: EStatus;

	product_options: ICsvOptionGroupOptionItem[];
	row_number: number;
}
