import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useCustomSearchParams } from './useCustomSearchParams';
import { THttpRequestParams } from './useHttpClient';

export interface IABConfigData {
	currentFeatureVariantSelection: {};
}

export const ABConfigContext = createContext<IABConfigData>({} as IABConfigData);

const useABContext = () => {
	return useContext(ABConfigContext);
};

const getABSelectionBySearchParams = (validServerConfiguration: any, groupParams: THttpRequestParams): any => {
	const result: any = {};

	Object.keys(validServerConfiguration).forEach((validKey) => {
		if (groupParams[validKey]) {
			result[validKey] = groupParams[validKey].toString();
		}
	});

	return result;
};

const mergeABConfigurations = Object.assign;

// NAIVE start implementation for AB configurations
export const ABConfigProvider = ({ children }: PropsWithChildren) => {
	const [, groupParams] = useCustomSearchParams();
	const [currentFeatureVariantSelection, setCurrentFeatureVariantSelection] = useState<
		IABConfigData['currentFeatureVariantSelection']
	>({});

	// ! useEffects
	useEffect(() => {
		setCurrentFeatureVariantSelection(
			mergeABConfigurations(
				currentFeatureVariantSelection,
				getABSelectionBySearchParams(currentFeatureVariantSelection, groupParams)
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! return
	const data = { currentFeatureVariantSelection };

	return <ABConfigContext.Provider value={data}>{children}</ABConfigContext.Provider>;
};

export default useABContext;
