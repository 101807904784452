import { RouteObject } from 'react-router-dom';
import { EVendorUserType } from 'types/api';

export const getAllowedRoutes = (
	role: EVendorUserType,
	routes: RouteObject[],
	isSuperAdmin: boolean
): RouteObject[] => {
	const availableRoutes = routes;

	if (isSuperAdmin) return availableRoutes;

	// if not super admin, we need to filter routes by user roles
	return availableRoutes.filter(({ handle }) => !handle?.roles?.length || handle.roles.includes(role));
};
