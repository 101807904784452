import { EPromotionTarget } from 'types/api/promotions';
import TranslatedLabel from 'components/TranslatedLabel';
import { ICardRadioGroupOptions } from '../SharedComponents/CardRadioGroup/types';
import { PROMOTION_TARGET_ICON_CONFIGS } from '../config';

export const PROMOTION_TARGET_OPTIONS: ICardRadioGroupOptions[] = Object.values(EPromotionTarget)
	.filter((type) => type !== EPromotionTarget.DEFAULT)
	.map(
		(type): ICardRadioGroupOptions => ({
			value: type,
			icon: PROMOTION_TARGET_ICON_CONFIGS[type],
			label: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={`create.form.target.options.${type}.title`}
				/>
			),
			description: (
				<TranslatedLabel
					nameSpace='campaigns'
					i18nKey={`create.form.target.options.${type}.description`}
				/>
			),
		})
	);
