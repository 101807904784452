import { FC, ReactNode } from 'react';
import { Typography } from 'antd';

export const FormSectionLabels: FC<{ label: ReactNode; extra: ReactNode }> = ({ label, extra }) => (
	<>
		<Typography.Title
			level={4}
			style={{ marginBottom: 0 }}
		>
			{label}
		</Typography.Title>
		<Typography.Text type='secondary'>{extra}</Typography.Text>
	</>
);
