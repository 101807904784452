import { getProductInfoCell } from './helper';
import { IProductDetailsTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { SkuBarcodeCell } from './SkuBarcodeCell';
import { ColumnsType } from 'antd/lib/table';

export const PRODUCT_DETAILS_COLUMNS: ColumnsType<IProductDetailsTableData> = [
	{
		key: 'expand_column',
		width: '36px',
	},
	{
		key: 'category_name',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.category_name'
			/>
		),
		dataIndex: 'category_name',
		ellipsis: true,
	},
	{
		key: 'sub_category_name',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.sub_category_name'
			/>
		),
		dataIndex: 'sub_category_name',
		ellipsis: true,
	},
	{
		key: 'product_info',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.product_info'
			/>
		),
		render: (_, { product_info }) => getProductInfoCell(product_info),
	},
	{
		key: 'variant_or_option_name',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.variant_or_option_name'
			/>
		),
		dataIndex: 'variant_or_option_name',
	},
	{
		key: 'variant_sku_barcode',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.variant_sku_barcode'
			/>
		),
		render: (_, record) => (
			<SkuBarcodeCell
				sku={record.variant_sku}
				barcode={record.variant_barcode}
			/>
		),
		ellipsis: true,
	},
	{
		key: 'unit_price',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.unit_price'
			/>
		),
		dataIndex: 'unit_price',
	},
	{
		key: 'quantity',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.quantity'
			/>
		),
		render: (_, record) => {
			return record.is_product_row
				? record.product_quantity
				: `${record.product_quantity * record.option_quantity} (${record.product_quantity} x ${
						record.option_quantity
				  }) `;
		},
	},
	{
		key: 'total_cost',
		title: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='shared.entity.total_cost'
			/>
		),
		dataIndex: 'total_cost',
		width: '200px',
	},
];
