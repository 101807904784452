import { ECampaignFormFields } from 'types/api/promotions';

export const PROMOTION_CAMPAIGN_LIST_TABLE: ECampaignFormFields[] = [
	ECampaignFormFields.ID,
	ECampaignFormFields.TITLE,
	ECampaignFormFields.TARGET,
	ECampaignFormFields.USAGE_TYPE,
	ECampaignFormFields.DISCOUNT_TYPE,
	ECampaignFormFields.CREATED_BY,
	ECampaignFormFields.TIME_RANGE,
	ECampaignFormFields.STATUS,
];
