import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Property } from 'csstype';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EOrderPaymentStatus } from 'types/api';
import { IPaymentStatusTagProps } from './types';
import { Tag, Tooltip } from 'antd';

const COLOR_CONFIG: Record<EOrderPaymentStatus, Property.Color> = {
	[EOrderPaymentStatus.NOT_PAID]: 'warning',
	[EOrderPaymentStatus.PAID]: 'success',
};

export const PaymentStatusTag: FC<IPaymentStatusTagProps> = memo(({ status }) => {
	const { t } = useTranslation('common');

	// !render
	return (
		<Tooltip title={t('order.payment.status.tooltip')}>
			<Tag
				color={COLOR_CONFIG[status]}
				style={DEFAULT_TAG_STYLES}
			>
				{t(`order.payment.status.${status}`)}
			</Tag>
		</Tooltip>
	);
});
