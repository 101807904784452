import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { IOrderProductDetails, IProductSelectedOptions } from 'types/api';
import { IProductDetailsTableData, IProductInfo } from './types';
import { Space } from 'antd';

const transformIProductSelectedOptionsToIProductDetailsTableData = (
	option: IProductSelectedOptions,
	productQuantity: number,
	productIndex: number,
	optionIndex: number
): IProductDetailsTableData => {
	const { id, name, unit_price, quantity: optionQuantity } = option;

	return {
		key: `option_${id}_${optionIndex}_${productIndex}`, // ! Ensure that we have unique keys
		sub_category_name: '',
		category_name: '',
		product_info: { name: '', special_request: '' },
		variant_or_option_name: `(x${optionQuantity}) ${name}`,
		unit_price,
		option_quantity: optionQuantity,
		product_quantity: productQuantity,
		total_cost: +(unit_price * optionQuantity * productQuantity).toFixed(2),
		is_product_row: false,
	};
};

export const transformProductDetailsToTableData = (
	products: IOrderProductDetails[] = []
): IProductDetailsTableData[] => {
	return products.map<IProductDetailsTableData>((product, productIndex) => {
		const {
			id,
			name,
			sub_category_name,
			category_name,
			variant,
			special_request = '',
			unit_price,
			quantity: productQuantity,
		} = product;

		const optionsList = variant.options?.map((option, optionIndex) =>
			transformIProductSelectedOptionsToIProductDetailsTableData(
				option,
				productQuantity,
				productIndex,
				optionIndex
			)
		);

		return {
			key: `product_${id}_${productIndex}`,
			sub_category_name: sub_category_name ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER,
			category_name: category_name ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER,
			product_info: { name, special_request },
			variant_or_option_name: variant.name,
			options: optionsList?.length ? optionsList : undefined, // ! Antd table needs an "undefined" to not present the "expand column"
			unit_price,
			option_quantity: 0,
			product_quantity: productQuantity,
			total_cost: +(unit_price * productQuantity).toFixed(2),
			is_product_row: true,
		};
	});
};

export const getProductInfoCell = ({ name, special_request }: IProductInfo): JSX.Element => {
	const specialRequestSpanStyle = {
		fontSize: '12px',
		color: 'var(--info-color)',
	};

	// ! render
	return special_request?.length ? (
		<Space
			size={2}
			direction='vertical'
		>
			{name}
			<span style={specialRequestSpanStyle}>{special_request}</span>
		</Space>
	) : (
		<>{name}</>
	);
};
