import { CSSProperties, ReactNode } from 'react';
import { EUserTypes } from 'types/common';
import { CarOutlined, SettingOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

export const USER_TYPES_TAG_COLORS: Record<EUserTypes, CSSProperties['color']> = {
	[EUserTypes.ADMIN]: 'orange',
	[EUserTypes.BRANCH]: 'purple',
	[EUserTypes.CUSTOMER]: 'cyan',
	[EUserTypes.DRIVER]: 'geekblue',
	[EUserTypes.GUEST]: 'cyan',
	[EUserTypes.INTEGRATION]: 'orange',
	[EUserTypes.OPERATIONS]: 'geekblue',
	[EUserTypes.SYSTEM]: 'orange',
	[EUserTypes.VENDOR]: 'purple',
};

export const USER_TYPES_TAG_ICONS: Record<EUserTypes, ReactNode> = {
	[EUserTypes.ADMIN]: <UserOutlined />,
	[EUserTypes.BRANCH]: <ShopOutlined />,
	[EUserTypes.CUSTOMER]: <UserOutlined />,
	[EUserTypes.DRIVER]: <CarOutlined />,
	[EUserTypes.GUEST]: <UserOutlined />,
	[EUserTypes.INTEGRATION]: <UserOutlined />,
	[EUserTypes.OPERATIONS]: <SettingOutlined />,
	[EUserTypes.SYSTEM]: <UserOutlined />,
	[EUserTypes.VENDOR]: <ShopOutlined />,
};
