import TranslatedLabel from 'components/TranslatedLabel';

export const DEFAULT_STATUS = '500';

export const DEFAULT_TITLE = (
	<TranslatedLabel
		nameSpace='error-page'
		i18nKey='general_error'
	/>
);
