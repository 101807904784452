import { EBranchSelectionTableData, IBranchSelectionTableData } from './types';
import { OpenStatusTag } from 'pages/Branches/helper';
import { ColumnsType } from 'antd/lib/table';

export const SELECT_BRANCH_DRAWER_TABLE_COLUMNS: ColumnsType<IBranchSelectionTableData> = [
	{
		key: 'type',
		title: 'type',
		dataIndex: 'type',
		width: 150,
	},
	{
		key: 'name',
		title: 'Name',
		dataIndex: 'name',
	},
	{
		key: 'status',
		title: 'Status',
		width: 125,
		render: (_, { status, type }) => {
			if (type === EBranchSelectionTableData.VENDOR) {
				return null;
			}

			// TODO: requires changes @tlsltaken for scheduled.is_opened
			return <OpenStatusTag opened={status} />;
		},
	},
];
