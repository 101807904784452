import { ITabItemConfig } from 'types/common';

export enum EVendorTabsKeys {
	BANK_INFO = 'bank-info',
	BRANCHES = 'branches',
	CATEGORIES = 'categories',
	DEEP_LINK = 'deep-link',
	DETAILS = 'details',
	DOCUMENTS = 'documents',
	MENUS = 'menus',
	PRODUCTS = 'products',
	INVOICES = 'invoices',
	STAFF = 'staff',
	WORKING_SHIFTS = 'working-shifts',
}

export interface IVendorInfoTabItem extends ITabItemConfig {
	key: EVendorTabsKeys;
}
