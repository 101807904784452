import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalTimeDate, useCountry } from 'hooks';
import { EPromotionDiscountType } from 'types/api';
import { promotion_target_config } from '../types';
import {
	IDrawerFooterProps,
	IRecommendationCampaignPreviewCallToActions,
	IRecommendedCampaignDrawerProps,
} from './types';
import CustomDrawer from 'components/CustomDrawer';
import { PROMOTION_TARGET_ICON_CONFIGS } from 'pages/Campaigns/config';
import FieldCard from '../../FieldCard';
import { formatDateCampaignDate } from '../../TimeRangeSlider/TimeRangeSlider.configs';
import styles from './RecommendedCampaignDrawer.module.css';
import DrawerFooter from './RecommendedCampaignDrawerFooter';
import DrawerHeader from './RecommendedCampaignDrawerHeader';
import dayjs from 'dayjs';
import { Col, Flex, Image, Row, Space, Typography } from 'antd';
import recommendedImage from 'assets/images/campaign-recommended.svg';

const RecommendedCampaignDrawer: FC<
	Pick<IDrawerFooterProps, 'isLaunching'> &
		IRecommendedCampaignDrawerProps &
		IRecommendationCampaignPreviewCallToActions
> = ({ campaignData, open, handleOnClose, isLaunching, ...props }) => {
	const { t: tCampaigns } = useTranslation('campaigns');

	const { currentCountry } = useCountry();

	return (
		<CustomDrawer
			title={<DrawerHeader campaign={campaignData} />}
			footer={
				<DrawerFooter
					record={campaignData}
					isLaunching={isLaunching}
					{...props}
				/>
			}
			size='large'
			open={open}
			closable
			onClose={handleOnClose}
		>
			{campaignData && (
				<Flex
					vertical
					gap={42}
				>
					<Space
						direction='vertical'
						size={16}
					>
						<Space align='start'>
							<Flex
								justify='center'
								align='center'
								className={styles.recommendation_icon}
								style={{
									color: promotion_target_config[campaignData.configurations.target].color,
									backgroundColor:
										promotion_target_config[campaignData.configurations.target].background,
								}}
							>
								{PROMOTION_TARGET_ICON_CONFIGS[campaignData.configurations.target]}
							</Flex>

							<Typography.Title
								level={3}
								style={{ margin: 0 }}
							>
								{tCampaigns(`enums.target.${campaignData.configurations.target}`)}
							</Typography.Title>
						</Space>

						<Typography.Text type='secondary'>{campaignData.info.description}</Typography.Text>
					</Space>

					<Flex
						gap={24}
						vertical
					>
						<Row gutter={[24, 24]}>
							<Col span={12}>
								<FieldCard
									fieldName={tCampaigns('recommended_drawer.goal.label')}
									fieldValue={tCampaigns(`enums.target.${campaignData.configurations.target}`)}
									tooltipTitle={tCampaigns(
										`recommended_drawer.goal.tooltip.${campaignData.configurations.target}`
									)}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 24]}>
							{campaignData.duration.start_date && (
								<Col span={12}>
									<FieldCard
										fieldName={tCampaigns('recommended_drawer.start_date.label')}
										fieldValue={<LocalTimeDate date={campaignData.duration.start_date} />}
										tooltipTitle={tCampaigns(`recommended_drawer.start_date.tooltip`)}
									/>
								</Col>
							)}
							{campaignData.duration.end_date && (
								<Col span={12}>
									<FieldCard
										fieldName={tCampaigns('recommended_drawer.end_date.label')}
										fieldValue={<LocalTimeDate date={campaignData.duration.end_date} />}
										tooltipTitle={tCampaigns(`recommended_drawer.end_date.tooltip`)}
									/>
								</Col>
							)}

							<Col span={24}>
								<FieldCard
									fieldName={tCampaigns('recommended_drawer.duration.label')}
									fieldValue={formatDateCampaignDate(
										dayjs.duration(campaignData.duration.time_range, 'seconds')
									)}
									tooltipTitle={tCampaigns(`recommended_drawer.duration.tooltip`)}
								/>
							</Col>
							<Col span={12}>
								<FieldCard
									fieldName={tCampaigns('recommended_drawer.discount.label')}
									fieldValue={
										<>
											{campaignData.configurations.discount_type ===
											EPromotionDiscountType.FREE_DELIVERY ? (
												tCampaigns('enums.discount_type.free_delivery')
											) : (
												<Space>
													{campaignData.configurations.discount_amount}
													{campaignData.configurations.discount_type ===
														EPromotionDiscountType.PERCENTAGE && '%'}
													{campaignData.configurations.discount_type ===
														EPromotionDiscountType.AMOUNT &&
														currentCountry?.display_currency_code}
												</Space>
											)}
										</>
									}
									tooltipTitle={tCampaigns(`recommended_drawer.discount.tooltip`)}
								/>
							</Col>
							<Col span={12}>
								<FieldCard
									fieldName={tCampaigns('recommended_drawer.usage_type.label')}
									fieldValue={tCampaigns(
										`enums.usage_type.${campaignData.configurations.usage_type}`
									)}
									tooltipTitle={tCampaigns(
										`recommended_drawer.usage_type.tooltip.${campaignData.configurations.usage_type}`
									)}
								/>
							</Col>

							{campaignData.configurations.free_delivery && (
								<Col span={24}>
									<FieldCard
										fieldName={tCampaigns('recommended_drawer.delivery_settings.label')}
										fieldValue={tCampaigns(`recommended_drawer.free_delivery.label`)}
										tooltipTitle={tCampaigns(`recommended_drawer.free_delivery.tooltip`)}
									/>
								</Col>
							)}
						</Row>
					</Flex>

					<Row
						align={'middle'}
						justify={'center'}
						style={{ marginTop: 48 }}
					>
						<Image
							preview={false}
							alt='recommended_img'
							src={recommendedImage}
						/>
					</Row>
				</Flex>
			)}
		</CustomDrawer>
	);
};

export default RecommendedCampaignDrawer;
