import { THttpRequestConfig } from 'hooks';
import { ECampaignFormFields, EPromotionType, IPromotionCreatePayloadTemp } from 'types/api/promotions';

export const PROMOTIONS_API = {
	list: (
		vendor_id: number,
		type: EPromotionType,
		searchParams?: Partial<Record<ECampaignFormFields, undefined | string>>
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions`,
		method: 'GET',
		params: { type, vendor_id, ...searchParams },
	}),
	// ! CRUD

	get: (vendor_id: number, promotion_id: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendor_id}/promotions/${promotion_id}`,
		method: 'GET',
	}),

	create: (vendor_id: number, payload: IPromotionCreatePayloadTemp): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions`,
		method: 'POST',
		data: payload,
	}),
	// ! Other
	generatePromoCode: (vendor_id: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions/generate-code`,
		method: 'POST',
	}),
	// operations
	launch: (vendor_id: number, id: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions/${id}/launch`,
		method: 'POST',
	}),
	push: (vendor_id: number, id: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions/${id}/push`,
		method: 'POST',
	}),
	pause: (vendor_id: number, id: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions/${id}/pause`,
		method: 'POST',
	}),

	resume: (vendor_id: number, id: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions/${id}/resume`,
		method: 'POST',
	}),

	stop: (vendor_id: number, id: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendor_id}/promotions/${id}/stop`,
		method: 'POST',
	}),
};
