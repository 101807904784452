import { FC, ReactNode } from 'react';
import styles from './FieldCard.module.css';
import { Flex, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IFieldCardProps {
	fieldName: ReactNode;
	fieldValue: ReactNode;
	tooltipTitle?: ReactNode;
}

const FieldCard: FC<IFieldCardProps> = ({ fieldName, fieldValue, tooltipTitle }) => {
	return (
		<Flex
			vertical
			gap={12}
			className={styles.field_card}
			wrap='wrap'
		>
			<Flex
				align='center'
				gap={8}
			>
				<Typography.Text
					strong
					type={'secondary'}
				>
					{fieldName}
				</Typography.Text>

				{tooltipTitle && (
					<Tooltip title={tooltipTitle}>
						<InfoCircleOutlined className={styles.info_icon} />
					</Tooltip>
				)}
			</Flex>
			<Typography.Title
				level={5}
				style={{ marginBottom: 0 }}
			>
				{fieldValue}
			</Typography.Title>
		</Flex>
	);
};

export default FieldCard;
