import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_PAYMENT_TYPES_ICONS } from 'configs/orders';
import { EOrderPaymentMethods } from 'types/api';
import { Flex, Typography as T } from 'antd';

export interface IPaymentTypeLabelProps {
	amount: number;
	currency: string;
	paymentMethod: EOrderPaymentMethods;
}

const PaymentTypeLabel: FC<IPaymentTypeLabelProps> = ({ amount, currency, paymentMethod }) => {
	const { t: tCommon } = useTranslation('common');

	// ! render
	return (
		<Flex gap={8}>
			<Flex gap={4}>
				{ORDER_PAYMENT_TYPES_ICONS[paymentMethod]}
				{tCommon(`order.payment.types.${paymentMethod}`)}
			</Flex>
			<T.Text>
				{amount} {currency}
			</T.Text>
		</Flex>
	);
};

export default memo(PaymentTypeLabel);
