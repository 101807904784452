import { THttpRequestConfig } from 'hooks/useHttpClient';

export const COUNTRY_API = {
	getList: (search?: string): THttpRequestConfig => ({
		url: 'vendor/countries',
		method: 'GET',
		params: {
			search,
			sort_by: 'name',
		},
	}),
};
