import { createContext } from 'react';
import { Nullable } from 'types/common';

export interface IGoogleMapsContextData {
	mapApiKey: Nullable<string>;
	mapApiKeyLoading: boolean;
}

export const GoogleMapsContext = createContext<IGoogleMapsContextData>({
	mapApiKey: null,
	mapApiKeyLoading: false,
});
