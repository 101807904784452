import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'hooks';
import { INotification } from 'contexts/notifications/types';
import CustomDrawer from 'components/CustomDrawer';
import { Button, Card, Empty, Row, Space, Tooltip } from 'antd';
import { AudioMutedOutlined, AudioOutlined, DeleteOutlined, InfoCircleTwoTone } from '@ant-design/icons';

interface INotificationDrawerCardProps {
	notificationElement: INotification;
	index: number;
	deleteNotification: (id: number) => void;
}

const MuteSound: FC = () => {
	const { state, toggleMute } = useNotifications();
	const { t: tNotifications } = useTranslation('notifications', { keyPrefix: 'action_buttons.sound' });

	return (
		<Tooltip title={tNotifications(state.mute ? 'unmute' : 'mute')}>
			<Button onClick={toggleMute}>{state.mute ? <AudioMutedOutlined /> : <AudioOutlined />}</Button>
		</Tooltip>
	);
};

const NotificationDrawerCard: FC<INotificationDrawerCardProps> = memo(
	({ notificationElement, index, deleteNotification }) => {
		const onDelete = (id: number) => {
			deleteNotification(id);
		};

		const notificationTypeColors = {
			warning: '#faad14', // --ant-warning-color
			info: '#1890ff', // --ant-info-color
			success: '#52c41a', // --ant-success-color
			error: '#ff4d4f', // --ant-error-color
		};

		return (
			<Card
				key={index}
				className='notification_drawer_card'
				title={
					<Space>
						{notificationElement.type && (
							<InfoCircleTwoTone twoToneColor={notificationTypeColors[notificationElement.type]} />
						)}
						{notificationElement.title}
					</Space>
				}
				extra={
					<Button
						type='text'
						onClick={() => onDelete(notificationElement.id)}
					>
						<DeleteOutlined />
					</Button>
				}
				size='small'
			>
				<Space direction='vertical'>
					{notificationElement.description}

					<Row justify='end'>{notificationElement.btn}</Row>
				</Space>
			</Card>
		);
	}
);

export const NotificationsDrawer: FC = () => {
	const { state, deleteNotification, clear, toggleDrawer } = useNotifications();
	const { t: tNotifications } = useTranslation('notifications');

	const notificationsSorted = state.notifications.sort((a: INotification, b: INotification) => b.id - a.id);

	return (
		<CustomDrawer
			title={tNotifications('title')}
			placement='right'
			onClose={() => toggleDrawer()}
			open={state.drawerOpen}
		>
			<Space
				direction='vertical'
				size='middle'
				style={{ display: 'flex' }}
			>
				<Space>
					<MuteSound />
					<Button
						onClick={clear}
						disabled={!state.notifications.length}
					>
						{tNotifications('action_buttons.reset')}
					</Button>
				</Space>

				{state.notifications.length === 0 && (
					<Empty
						style={{ paddingTop: '32px' }}
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={tNotifications('no_notifications')}
					></Empty>
				)}

				{notificationsSorted.map((notificationElement: INotification, index: number) => (
					<NotificationDrawerCard
						notificationElement={notificationElement}
						index={index}
						key={index}
						deleteNotification={deleteNotification}
					></NotificationDrawerCard>
				))}
			</Space>
		</CustomDrawer>
	);
};
