import { AUTH_TOKEN_KEY, REFRESH_TOKEN_URL } from 'configs/api';
import { IStorageUtils } from './storage';
import * as yup from 'yup';

export const getRefreshTokenFromLocalStorage = (storage: IStorageUtils): string => {
	const yupSchema = yup.string().required();

	try {
		let token = storage.get(REFRESH_TOKEN_URL);

		return yupSchema.validateSync(token) ?? '';
	} catch (error) {
		return '';
	}
};

export const getAuthTokenFromLocalStorage = (storage: IStorageUtils): string => {
	const yupSchema = yup.string().required();

	try {
		let token = storage.get(AUTH_TOKEN_KEY);

		return yupSchema.validateSync(token) ?? '';
	} catch (error) {
		return '';
	}
};
