import { PropsWithChildren, createContext, useContext } from 'react';
import { useSelector } from 'store';
import { getSelectedCountry } from 'store/selectors';
import { ICountry } from 'types/api';

interface ICountryContextData {
	currency?: string;
	currentCountry?: ICountry;
}

const CountryContext = createContext<ICountryContextData>({} as ICountryContextData);

const useCountry = () => {
	return useContext(CountryContext);
};

const CountryProvider = ({ children }: PropsWithChildren) => {
	// ! selectors
	const currentCountry = useSelector(getSelectedCountry);

	const settingsData: ICountryContextData = {
		currency: currentCountry?.currency_code,
		currentCountry,
	};

	return <CountryContext.Provider value={settingsData}>{children}</CountryContext.Provider>;
};

export { useCountry, CountryProvider };
