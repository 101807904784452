import { generatePath } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { IInvoice } from 'types/api';
import { TInvoiceTable } from './types';

export const transformIInvoiceToTableData = (invoice: IInvoice): TInvoiceTable => {
	return {
		...invoice,
		key: invoice.id,
		vendor_path: generatePath(ABSOLUTE_ROUTES.VENDOR_DETAILS_TAB, { vendorId: invoice.vendor_id }),
	};
};
