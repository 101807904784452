import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER, KSA_COUNTRY_ISO_CODE } from 'configs/common';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import useLogos from 'hooks/useLogos';
import { IOrder } from 'types/api';
import { IOrderPrintLayoutProps } from './types';
import { transformProductDetailsToTableData } from 'pages/Orders/OrderDetails/Tabs/OrderInfo/helper';
import { IProductDetailsTableData } from 'pages/Orders/OrderDetails/Tabs/OrderInfo/types';
import styles from './OrderPrintReceiptLayout.module.css';
import { Col, Divider, Flex, Row, Space } from 'antd';

const OrderPrintReceiptLayout = React.forwardRef<any, IOrderPrintLayoutProps>(({ order, direction }, ref) => {
	const { t: tPrintOrder } = useTranslation('print-order');
	const { t: tCommon } = useTranslation('common');
	const { fullLogo } = useLogos();

	const [productDetails, setProductDetails] = useState<IProductDetailsTableData[]>([]);

	// ! useEffects
	useEffect(() => {
		if (!order) return;

		const productList = transformProductDetailsToTableData(order.products ?? []);
		setProductDetails(productList);
	}, [order]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! memo
	const productListCost = useMemo(
		() =>
			productDetails?.map((product) => {
				const optionsCost: number = (product.options ?? []).reduce(
					(acc, option) => acc + option.unit_price * +option.option_quantity,
					0
				);
				const productChoiceTotalCost = (product.unit_price + optionsCost) * +product.product_quantity;

				return productChoiceTotalCost;
			}),
		[productDetails]
	);

	// ! render
	if (!order || !productDetails?.length) return null;

	const { info, store, customer, payment, delivery_address }: IOrder = order;

	const orderItemsTotal = productDetails.reduce((acc, product) => {
		return acc + +product.product_quantity;
	}, 0);

	const OrderPrintDivider = () => (
		<Divider
			plain
			dashed
			style={{ borderColor: '#A8AFB6' }}
			className='ant-divider-with-text'
		/>
	);

	return (
		<div
			id='componentRef'
			ref={ref as React.RefObject<HTMLDivElement>}
		>
			<div
				className='print_root_layout'
				style={{ direction: direction as any }}
			>
				<table>
					<thead>
						<tr>
							<td>
								<div
									className={[
										'print_header_space',
										styles.print_header_space,
										styles.order_print_figma,
										styles.order_print_header_logo_container,
									].join(' ')}
								>
									<img
										className={styles.order_print_logo}
										alt='logo'
										src={fullLogo}
									/>
								</div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div className={styles.order_print_details}>
									<Flex
										gap={8}
										align='start'
									>
										<span className={styles.label}>{tPrintOrder('figma.label.order')}</span>
										<div className={styles.detail}>{info.code}</div>
									</Flex>

									<Flex
										gap={8}
										align='start'
									>
										<span className={styles.label}>{tPrintOrder('figma.label.branch')}</span>
										<div className={styles.detail}>{`${store.name}`}</div>
									</Flex>

									<Flex
										gap={8}
										align='start'
									>
										<span className={styles.label}>{tPrintOrder('figma.label.customer')}</span>
										<div className={styles.detail}>
											{customer.name || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
										</div>
									</Flex>

									<Flex
										gap={8}
										align='start'
									>
										<span className={styles.label}>{tPrintOrder('figma.label.mobile')}</span>
										<div className={styles.detail}>
											{customer.contact_number || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
										</div>
									</Flex>

									<Flex
										gap={8}
										align='start'
									>
										<span className={styles.label}>{tPrintOrder('figma.label.address')}</span>
										<div className={styles.detail}>
											{delivery_address?.address || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
										</div>
									</Flex>

									<Flex
										gap={8}
										align='start'
									>
										<span className={styles.label}>{tPrintOrder('figma.label.date')}</span>
										<div className={styles.detail}>
											<LocalTimeDate
												date={info.placed_at}
												format='MMM DD, YYYY - HH:mm'
											/>
										</div>
									</Flex>
								</div>

								<div className={styles.order_print}>
									<div>
										<table className={styles.order_print_product_list}>
											<thead>
												<tr style={{ textAlign: direction === 'ltr' ? 'left' : 'right' }}>
													<th>{tPrintOrder('figma.table.item')}</th>
													<th>{tPrintOrder('figma.table.quantity')}</th>
													<th>{tPrintOrder('figma.table.price')}</th>
												</tr>
											</thead>
											<tbody>
												{productDetails?.map((product, index) => (
													<tr
														key={index}
														className={styles.order_product_details}
													>
														<td>
															<div className={styles.order_product_name}>
																{product.product_info.name}
															</div>

															{product.product_info.name}
															{product.options?.length && (
																<p>
																	{`${tPrintOrder(
																		'figma.label.product_options'
																	)}: ${product.options
																		.map((option) => option.variant_or_option_name)
																		.join(', ')} `}
																</p>
															)}
															{product.product_info.special_request && (
																<p>
																	{`${tPrintOrder('figma.label.special_note')}: ${
																		product.product_info.special_request
																	}`}
																</p>
															)}
														</td>
														<td
															className={styles.order_print_product_table_quantity_column}
														>
															{`x ${product.product_quantity}`}
														</td>
														<td className={styles.order_print_product_table_price_column}>
															{`${payment.currency_code} ${productListCost[index]}`}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>

									<div>
										<OrderPrintDivider />
										<Row justify='space-between'>
											<Col
												className={styles.order_summary}
												style={{ textAlign: direction === 'ltr' ? 'left' : 'right' }}
											>
												<div>{tPrintOrder('summary.total_item_cost')}</div>
												<div>{tPrintOrder('summary.store_discount_amount')}</div>
												<div>{tPrintOrder('summary.promotion_discount_amount')}</div>
												<div>{tPrintOrder('summary.delivery_cost')}</div>
											</Col>
											<Col
												className={styles.order_summary + ' ' + styles.order_summary_values}
												style={{ textAlign: direction === 'ltr' ? 'right' : 'left' }}
											>
												<div>
													<div>{`${payment.currency_code} ${payment.total_item_cost}`}</div>
													<div>{`${payment.currency_code} ${payment.store_discount_amount}`}</div>
													<div>{`${payment.currency_code} ${payment.platform_discount_amount}`}</div>
													<div>{`${payment.currency_code} ${payment.delivery_cost}`}</div>
												</div>
											</Col>
										</Row>

										<OrderPrintDivider />

										<Row
											justify='end'
											style={{ direction: 'ltr' }}
										>
											<Col
												className={styles.order_final_summary}
												style={{ textAlign: direction === 'ltr' ? 'left' : 'right' }}
											>
												<div>{tPrintOrder('summary.to_pay')}</div>
												<div className={styles.order_final_summary_total_cost}>
													{`${payment.currency_code} ${payment.grand_total}`}
												</div>

												{payment.tax_description && (
													<div className={styles.order_final_summary_tax_description}>
														{payment.tax_description}
													</div>
												)}
											</Col>
										</Row>
									</div>

									<OrderPrintDivider />
									<Row
										justify='center'
										className={styles.last_info}
									>
										<Col>
											{store.country.iso_two_code !== KSA_COUNTRY_ISO_CODE && (
												<div>
													<Space>
														{tPrintOrder('figma.label.pay_method')}:{' '}
														{tCommon(`order.payment.types.${payment.method}`)}
													</Space>
												</div>
											)}
											<div>
												<Space>
													{tPrintOrder('figma.label.item_quantity')}: {orderItemsTotal}
												</Space>
											</div>
											{/* <div>{info.additional_info}</div> */}
										</Col>
									</Row>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td>
								<div className='print_footer_space'></div>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
});

export default OrderPrintReceiptLayout;
