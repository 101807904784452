import { ReactNode } from 'react';
import { EPromotionDiscountType, EPromotionUsageType } from 'types/api/promotions';
import { NumberOutlined, PercentageOutlined } from '@ant-design/icons';

export const PROMOTION_DISCOUNT_TYPE_SHOWS_MAX_DISCOUNT_AMOUNT_FILED: Record<EPromotionDiscountType, boolean> = {
	[EPromotionDiscountType.PERCENTAGE]: true,
	[EPromotionDiscountType.AMOUNT]: false,
	[EPromotionDiscountType.FREE_DELIVERY]: false,
	[EPromotionDiscountType.CASHBACK_PERCENTAGE]: true,
	[EPromotionDiscountType.CASHBACK_AMOUNT]: false,
};

export const USAGE_TYPE_ICONS: Record<EPromotionUsageType, ReactNode> = {
	[EPromotionUsageType.MANUAL]: <NumberOutlined />,
	[EPromotionUsageType.AUTOMATIC]: <PercentageOutlined />,
};
