import { EOnOffStatus } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { message } from 'antd';

const STATUS_MSG_KEY = 'branch_current_status_msg';

export const removeBranchCurrentStatusMessage = () => {
	message.destroy(STATUS_MSG_KEY);
};

export const setBranchCurrentStatusMessage = (currentStatus: EOnOffStatus) => {
	message.destroy(STATUS_MSG_KEY);
	if (currentStatus === EOnOffStatus.OFFLINE) {
		message.warning({
			duration: 0,
			content: (
				<TranslatedLabel
					nameSpace='branches'
					i18nKey='branch_closed'
				/>
			),
			key: STATUS_MSG_KEY,
		});
	}
};
