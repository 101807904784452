import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDrawerFooterProps } from './types';
import styles from './RecommendedCampaignDrawer.module.css';
import { Button, Col, Row } from 'antd';
import { EditOutlined, RocketOutlined } from '@ant-design/icons';

const DrawerFooter: FC<IDrawerFooterProps> = ({
	record,
	isLaunching,
	onEdit,
	handleLaunchCampaign: onLaunchCampaign,
}) => {
	const { t: tCampaigns } = useTranslation('campaigns');

	return (
		<Row gutter={[16, 16]}>
			<Col flex={1}>
				<Button
					size='large'
					loading={isLaunching}
					icon={<EditOutlined />}
					className={styles.action_button}
					onClick={() => onEdit(record)}
				>
					{tCampaigns('action_buttons.edit_campaign')}
				</Button>
			</Col>
			<Col flex={2}>
				<Button
					size='large'
					type='primary'
					htmlType='submit'
					loading={isLaunching}
					form='createCampaignForm'
					className={styles.action_button}
					icon={<RocketOutlined rotate={45} />}
					onClick={() => onLaunchCampaign(record)}
				>
					{tCampaigns('action_buttons.launch_campaign')}
				</Button>
			</Col>
		</Row>
	);
};

export default DrawerFooter;
