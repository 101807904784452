import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { ILinkBtnProps } from './types';
import { Tooltip } from 'antd';

const LinkButton: FC<ILinkBtnProps> = memo(({ label, icon, tooltip, ...props }) => {
	// ! render
	return (
		<Tooltip title={tooltip}>
			<NavLink {...props}>
				{icon} {label}
			</NavLink>
		</Tooltip>
	);
});

export default LinkButton;
