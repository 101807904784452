import { EAssignmentMode, EChoiceOption, EDeliveryMode, EOnOffStatus, Nullable } from '../common';
import { ICountry } from './countries';
import { IVertical } from './verticals';

// ! enums & types
type TShortWeekDay = 'ALL' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

type TBranchProductsPerRow = 1 | 2;

export enum EBranchStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
}

enum EBranchOfferTypes {
	NONE = 'none',
	AMOUNT = 'amount',
	PERCENTAGE = 'percentage',
}

enum EBranchPreparationMode {
	DEFAULT = 'default',
	AUTO = 'auto',
}

enum EBranchCategoryStatus {
	INACTIVE = 0,
	ACTIVE = 1,
}

enum EBranchProductSizeStatus {
	INACTIVE = 0,
	ACTIVE = 1,
}

export enum EBranchOperationAreaType {
	RADIUS = 'radius',
	POLYGON = 'polygon',
}

export enum EBranchScheduledOrderSupport {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

// ! interfaces
interface IBranchSchedule {
	shift_id: number;
	day: string;
	is_opened: boolean;
	opening_hours: string;
	opening_hours_slots: string[];
}

interface IBranchImages {
	logo: string;
	banner: string;
}

interface IBranchLocation {
	address: string;
	country: ICountry;
	lat: string;
	lng: string;
	operation_area_type: EBranchOperationAreaType;
	service_radius: number;
	timezone: string;
	timezone_id: number;
}

interface IBranchDeliveryInfo {
	fee: number;
	order_delivery_time: number;
	allow_cash_payment: EChoiceOption;
}

interface IBranchRatings {
	total: number;
	average: number;
}

export interface IBranchBasicInfo {
	name: string;
	name_ar: Nullable<string>;
	display_name: string;
	display_name_ar: Nullable<string>;

	description: string;
	description_ar: string;

	status: EBranchStatus;
	current_status: EOnOffStatus;

	products_per_row: TBranchProductsPerRow;
	business_model: string;
	cam_reg_code?: string;
	order_min_amount: number;
	scheduled_order_support: EBranchScheduledOrderSupport;
	preparation_mode: EBranchPreparationMode;
	preparation_time: number;
	delivery_mode: EDeliveryMode;
	delivery_mode_description: string;
	delivery_mode_description_detailed: string;
	assignment_mode: EAssignmentMode;
}

interface IBranchContactInfo {
	phone_country_code: Nullable<string>;
	contact_number: Nullable<string>;
}

export interface IBranch {
	id: number;
	info: IBranchBasicInfo;
	contact_info: IBranchContactInfo;
	images: IBranchImages;
	location: IBranchLocation;
	delivery: IBranchDeliveryInfo;
	ratings: IBranchRatings;
	schedule: IBranchSchedule;
	menu_id: number;
	verticals: IVertical[];
}

export interface IBranchListItemResponse {
	id: IBranch['id'];
	info: {
		name: IBranchBasicInfo['name'];
		name_ar: IBranchBasicInfo['name_ar'];
		current_status: IBranchBasicInfo['current_status'];
		status: IBranchBasicInfo['status'];
		scheduled_order_support: IBranchBasicInfo['scheduled_order_support'];
	};
	ratings: {
		average: IBranchRatings['average'];
	};
	schedule: {
		is_opened: IBranchSchedule['is_opened'];
	};
}

// * Categories & Products
export interface IBranchCategory {
	id: number;
	name: string;
	name_ar: string;
	icon: string;
	store_id: number;
	parent_id: number;
	status: EBranchCategoryStatus;
	sub_categories?: IBranchCategory[];
}

interface IBranchProductSize {
	id: number;
	name: string;
	name_ar: string;
	price: number;
	status: EBranchProductSizeStatus;
}

export interface IBranchProduct {
	id: number;
	store_id: number;
	name: string;
	name_ar: string;
	image: string;
	price?: number;
	status: EBranchCategoryStatus;
	price_after_discount?: number;
	discount_amount?: number;
	discount_type?: EBranchOfferTypes;
	category_id: number;
	category_name: string;
	sub_category_id: number;
	description?: string;
	description_ar?: string;
	has_options: boolean;
	sizes?: IBranchProductSize[];
}

// * Work Shifts
export interface IWorkShift {
	id: number;
	name: string;
	name_ar: string;

	branches?: IWorkShiftAssociatedBranch[];
}

export interface IWorkShiftListItemResponse {
	id: IWorkShift['id'];
	name: IWorkShift['name'];
	name_ar: IWorkShift['name_ar'];
	branches?: Array<{
		id: IBranch['id'];
		name: IBranchBasicInfo['name'];
	}>;
}

export interface IWorkShiftAssociatedBranch {
	id: number;
	name: string;
	name_ar: string;
}

export interface IWorkShiftTiming {
	timings: IBranchTiming[];
}
export interface IWorkShiftCreateRequest {
	name: string;
	name_ar: string;
}

// * Timings
export interface IBranchTimingsSchedule {
	open_time: string;
	close_time: string;
}

export interface IBranchTiming {
	day: TShortWeekDay;
	schedule: IBranchTimingsSchedule[];
}

// * Operation Area
export interface IBranchOperationAreaCoordinates {
	lat: number;
	lng: number;
}

interface IBranchOperationPolygonArea {
	type: 'Polygon';
	coordinates: number[][][]; // GeoJSON Position Format
}

export interface IBranchOperationArea {
	id: number;
	store_id: number;
	operation_area: IBranchOperationPolygonArea;
	updated_at: Date | string;
}

export enum EBranchBusinessModel {
	CAM = 'cam', // Customer Acquisition Model
	PCM = 'pcm', // Platform Commission Model
}
