import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'hooks';
import useAppBrand from './useAppBrandName';

export interface IUseAppTitle {
	document_title: string;
	setAppDocumentTitle: Dispatch<SetStateAction<string>>;
}
const changeFavicon = (href: string) => {
	// Ensure we have access to the document, i.e. we are in the browser.
	if (typeof window === 'undefined') return;

	const link: HTMLBaseElement =
		window.document.querySelector("link[rel*='icon']") || window.document.createElement('link');
	link.href = href;

	window.document.getElementsByTagName('head')[0].appendChild(link);
};

export const useAppTitle = (title?: string): IUseAppTitle => {
	const { brand } = useAppBrand();
	const { t: tNotifications } = useTranslation('notifications');
	const [appDocumentTitle, setAppDocumentTitle] = useState(title ?? document.title);
	const [secondsCounter, setSeconds] = useState<number>(0);

	const {
		state: { newNotificationsCounter },
	} = useNotifications();

	useEffect(() => {
		const faviconPathPrefix = `/${brand}`;

		// alternate every second between the notification title and the default one
		if (newNotificationsCounter && secondsCounter % 2) {
			changeFavicon(`${faviconPathPrefix}-favicon-notification.ico`);

			document.title = tNotifications('browser_tab_notification_warning', { count: newNotificationsCounter });

			return;
		}

		changeFavicon(`${faviconPathPrefix}-favicon.ico`);

		document.title = appDocumentTitle;
	}, [secondsCounter, appDocumentTitle, tNotifications, newNotificationsCounter, brand]);

	useEffect(() => {
		let interval: NodeJS.Timer | undefined = undefined;
		if (newNotificationsCounter > 0) {
			interval = setInterval(() => {
				setSeconds((seconds: number) => seconds + 1);
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [newNotificationsCounter]);

	return { document_title: appDocumentTitle, setAppDocumentTitle };
};
