import { IVendorAuth, IVendorUserAssociatedBranches } from 'types/api';
import { EOnOffStatus } from 'types/common';
import { EBranchSelectionTableData, IBranchSelectionTableData } from './types';
import { Space } from 'antd';

export const getTableKey = (vendorId: number, branchId?: number) => [vendorId, branchId].filter((a) => a).join('-');

export const transformVendorBranchesToTableData = (
	vendorId: number,
	branches: IVendorUserAssociatedBranches[]
): IBranchSelectionTableData[] => {
	return branches
		.filter((branch) => branch.vendor_id === vendorId)
		.map(({ id, name, name_ar, current_status }) => ({
			key: getTableKey(vendorId, id),
			id: EBranchSelectionTableData.BRANCH + id,
			vendorId,
			branchId: id,
			type: EBranchSelectionTableData.BRANCH,
			name: (
				<Space>
					{name} |{name_ar}
				</Space>
			),
			status: current_status,
		}));
};

export const transformVendorsDataToTableData = (
	vendors: IVendorAuth[],
	branches: IVendorUserAssociatedBranches[]
): IBranchSelectionTableData[] => {
	return vendors.map((vendor) => ({
		key: getTableKey(vendor.id),
		id: EBranchSelectionTableData.VENDOR + vendor.id,
		vendorId: vendor.id,
		type: EBranchSelectionTableData.VENDOR,
		name: vendor.name,
		status: EOnOffStatus.OFFLINE,
		children: transformVendorBranchesToTableData(vendor.id, branches),
	}));
};
