import { IOrder } from 'types/api';
import { IProductsTableSummary } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const getTableSummary = ({ payment, promotions_used }: IOrder): IProductsTableSummary[] => {
	const translationPrefix = 'order_details.tabs.details.product_details.summary';

	return [
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey={`${translationPrefix}.item_total`}
				/>
			),
			value: payment.total_item_cost,
		},
		{
			label: (
				<Space size={6}>
					<TranslatedLabel
						nameSpace='orders'
						i18nKey={`${translationPrefix}.discount`}
					/>
					<Tooltip
						title={
							<TranslatedLabel
								nameSpace='orders'
								extra={{ promotions: promotions_used?.vendor }}
								i18nKey={`order_details.tabs.details.product_details.summary.${
									!promotions_used?.vendor.length ? 'tooltip_no_promotion' : 'tooltip_promotions_used'
								}`}
							/>
						}
					>
						<InfoCircleOutlined style={{ color: 'grey' }} />
					</Tooltip>
				</Space>
			),
			value: payment.store_discount_amount,
		},
		{
			label: (
				<Space size={6}>
					<TranslatedLabel
						nameSpace='orders'
						i18nKey={`${translationPrefix}.promotion_discount`}
					/>
					<Tooltip
						title={
							<TranslatedLabel
								nameSpace='orders'
								extra={{ promotions: promotions_used?.platform }}
								i18nKey={`${translationPrefix}.${
									!promotions_used?.platform.length
										? 'tooltip_no_promotion'
										: 'tooltip_promotions_used'
								}`}
							/>
						}
					>
						<InfoCircleOutlined style={{ color: 'grey' }} />
					</Tooltip>
				</Space>
			),
			value: payment.platform_discount_amount,
		},
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey={`${translationPrefix}.delivery_charge`}
				/>
			),
			value: payment.delivery_cost,
		},
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey={`${translationPrefix}.total`}
				/>
			),
			extraInfo: payment.tax_description,
			value: `${payment.grand_total} ${payment.currency_code}`,
		},
	];
};
