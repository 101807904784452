import { THttpRequestConfig } from 'hooks/useHttpClient';
import { EBranchScheduledOrderSupport } from 'types/api';
import { EOnOffStatus } from 'types/common';

export const VENDOR_BRANCH_API = {
	fetchList: (vendorId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores`,
		method: 'GET',
	}),

	get: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}`,
		method: 'GET',
	}),

	statusUpdate: (vendorId: number, branchId: number, status: EOnOffStatus): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/current-status`,
		method: 'PATCH',
		data: { status },
	}),

	setBulkScheduleOrderMode: (
		vendorId: number,
		data?: { scheduled_order_support: EBranchScheduledOrderSupport; store_ids?: number[] }
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/scheduled-order-support`,
		method: 'PATCH',
		data,
	}),

	bulkStatusUpdate: (vendorId: number, status: EOnOffStatus): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/current-status`,
		method: 'PATCH',
		data: { status },
	}),

	getOperationArea: (vendorId: number, branchId: string | number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/operation-area`,
		method: 'GET',
	}),
};
