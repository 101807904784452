import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EOnOffStatus } from 'types/common';
import LoadingTag from 'components/LoadingTag';
import { Tag } from 'antd';

export const OpenStatusTag: FC<{ opened?: EOnOffStatus }> = ({ opened }) => {
	const { t: tCommon } = useTranslation('common');

	if (opened === undefined) {
		return <LoadingTag />;
	}

	const isOpened = EOnOffStatus.ONLINE === opened;

	let color = isOpened ? 'green' : 'red';
	let labelKey = isOpened ? 'open' : 'close';

	// ! render
	return (
		<Tag
			style={DEFAULT_TAG_STYLES}
			color={color}
		>
			{tCommon(`switch_states.${labelKey}`)}
		</Tag>
	);
};
