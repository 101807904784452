import { useRef } from 'react';
import TranslatedLabel from 'components/TranslatedLabel';
import { App, ModalFuncProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type IOpenConfirmModalProps = ModalFuncProps & Required<Pick<ModalFuncProps, 'title' | 'onOk'>>;

export const useConfirmModal = () => {
	const { modal } = App.useApp();

	// ! refs
	const waitingRef = useRef(false);

	// ! helpers
	const openConfirmModal = (args: IOpenConfirmModalProps) => {
		// prevent multiple modals when clicking multiple times
		if (waitingRef.current) return;
		waitingRef.current = true;

		setTimeout(() => {
			waitingRef.current = false;
		}, 400);

		return modal.confirm({
			...args,
			cancelText: args.cancelText || <TranslatedLabel i18nKey='action_buttons.cancel' />,
			okText: args.okText || <TranslatedLabel i18nKey='action_buttons.yes' />,
			centered: args.centered || true,
			icon: args.icon || <ExclamationCircleOutlined />,
		});
	};

	return { openConfirmModal };
};
