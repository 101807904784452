import { IUseLocaleTimeUtilsProps } from 'hooks/useLocaleTimeUtils/useLocaleTimeUtils';
import { IOrderLog } from 'types/api';
import { ITimelineInfo } from './types';

export const reduceTimelineData = (
	data: IOrderLog[],
	formatDate: IUseLocaleTimeUtilsProps['formatDate']
): ITimelineInfo[] => {
	return data.map(
		(log, index: number): ITimelineInfo => ({
			userId: log.user_id,
			userName: log.user_name,
			userRole: log.user_role,
			status: log.status,
			createdAt: formatDate(log.created_at, true),
			comment: log.comment,
			lastItem: index === data.length - 1,
		})
	);
};
