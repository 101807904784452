import { FC, useMemo } from 'react';
import { useIntervalTimer } from 'hooks/useIntervalTimer';
import { UTC_TIME_ZONE } from 'hooks/useLocaleTimeUtils';
import { IClockProps } from './types';
import dayjs from 'dayjs';

export const Clock: FC<IClockProps> = ({ timeZoneName, timezone = undefined }) => {
	const { intervalUpdate } = useIntervalTimer(1000 * 60);

	const formatedTime = useMemo(() => {
		const currentTime = timezone && timezone !== UTC_TIME_ZONE ? dayjs().tz(timezone) : dayjs().utc();

		return currentTime.format('HH:mm z');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timezone, intervalUpdate]);

	const tzName = timeZoneName ?? timezone ?? UTC_TIME_ZONE;

	return (
		<>
			{tzName} - {formatedTime}
		</>
	);
};
