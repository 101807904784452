import { ReactNode } from 'react';
import TranslatedLabel from 'components/TranslatedLabel';
import dayjs from 'dayjs';
import dayJsDuration from 'dayjs/plugin/duration';
import dayJsTimezone from 'dayjs/plugin/timezone';
import dayJsUtc from 'dayjs/plugin/utc';
import { Space } from 'antd';

dayjs.extend(dayJsDuration);
dayjs.extend(dayJsTimezone);
dayjs.extend(dayJsUtc);

export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_INTL_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_INTL_SECONDS_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const transformToBEAcceptanceFormat = (date?: string | Date | dayjs.Dayjs) => {
	return dayjs(date).utc().toISOString();
};

// ! Time Diff
const getTimeDiffLabel = (duration: dayJsDuration.Duration): string => {
	const seconds = duration.seconds();
	const minutes = duration.minutes();
	const hours = duration.hours();
	const days = duration.days();

	let diff = '';

	if (+days > 0) diff += `${days} d `;

	if (+hours > 0) diff += `${hours} h `;

	if (+minutes > 0) diff += `${minutes} min `;

	if (!days && !hours && !minutes && +seconds >= 0) diff += 'a few seconds ';

	return (diff += 'ago');
};

export const getTimeDiff = (date: string | Date): string => {
	const now = dayjs();
	const utcDate = dayjs(date);

	return getTimeDiffLabel(dayjs.duration(now.diff(utcDate)));
};

export const formatDateRange = (sTime: number): ReactNode => {
	let minutes = Math.floor(sTime / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.floor(hours / 24);

	let timeDiffHours = hours - days * 24;
	const remainingMinutes = minutes % 60;

	let resultingString: ReactNode[] = [];
	if (days) {
		resultingString.push(
			days,
			<TranslatedLabel
				i18nKey='time_units.day'
				extra={{ count: days }}
			/>
		);
	}

	if (timeDiffHours) {
		resultingString.push(
			timeDiffHours,
			<TranslatedLabel
				i18nKey='time_units.hour'
				extra={{ count: timeDiffHours }}
			/>
		);
	}

	if (remainingMinutes && (timeDiffHours || remainingMinutes)) {
		resultingString.push(
			remainingMinutes,
			<TranslatedLabel
				i18nKey='time_units.minute'
				extra={{ count: remainingMinutes }}
			/>
		);
	}

	return resultingString.length ? (
		<Space size='small'>
			{resultingString.map((a, index) => (
				<span key={index}>{a}</span>
			))}
		</Space>
	) : null;
};
