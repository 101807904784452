import React, { FC, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ORDERS_API } from 'configs/api';
import { REFRESH_DATA_TTL } from 'configs/common';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import {
	useAuthenticatedInformation,
	useGlobalConfigs,
	useGlobalRefreshTimerContext,
	useHandleErrors,
	useLocaleTimeUtils,
	useNotifications,
} from 'hooks';
import { generateNewOrderNotification } from './helper';
import { EOrderStatus, IDispatcherOrder } from 'types/api';
import { IListResponse } from 'types/common';
import { IBranchDispatcherOrderListFilterRequest } from 'pages/Dispatcher/types';

export const NewOrdersFetcher: FC = () => {
	const refreshController = useGlobalRefreshTimerContext();
	const { http } = useGlobalConfigs();
	const navigate = useNavigate();
	const { getStartDateParamToFilter } = useLocaleTimeUtils();
	const notificationsController = useNotifications();
	const { selectedBranchIdList: branchIdList } = useAuthenticatedInformation();
	const { handleError } = useHandleErrors();

	// ! states
	const [temporaryFixBeforePublishSubscribe, setTemporaryFixBeforePublishSubscribe] = useState<
		Record<number, boolean>
	>({});

	// ! handlers
	const openNewOrdersNotification = (order: IDispatcherOrder) => {
		const onClickGoToOrderDetails = (evt?: React.MouseEvent) => {
			if (evt) {
				evt.preventDefault();
				evt.stopPropagation();
			}
			notificationsController.toggleDrawer(false);
			notificationsController.stopSound();

			navigate(generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { id: order.id }));
		};

		const newNotification = generateNewOrderNotification(order, onClickGoToOrderDetails);

		notificationsController.push(newNotification);
	};

	const processResponse = (response: AxiosResponse<IListResponse<IDispatcherOrder>>): void => {
		const orderList: IDispatcherOrder[] = response.data.data;

		// ! Since we are fetching windows of (3 * REFRESH_DATA_TTL) the same order will appear multiple times
		// ! need to keep the state that that order was already notified to not repeat it
		const newOrderList = orderList.filter((order) => !temporaryFixBeforePublishSubscribe[order.id]);

		newOrderList.forEach((order) => {
			if (order.actions.can_ring) {
				openNewOrdersNotification(order);
			}
		});

		setTemporaryFixBeforePublishSubscribe(
			newOrderList.reduce((acc, order) => ({ ...acc, [order.id]: true }), {
				...temporaryFixBeforePublishSubscribe,
			})
		);

		return;
	};

	const fetchBranchList = (signal: AbortSignal): AxiosPromise<IListResponse<IDispatcherOrder>> => {
		const params: Partial<IBranchDispatcherOrderListFilterRequest> = {
			page: '1',
			per_page: '100',
			start_date: getStartDateParamToFilter(0, 3 * REFRESH_DATA_TTL),
			sort_by: 'new_at',
			order_by: 'ASC',
			ringable: true,
			status: [EOrderStatus.NEW],
		};

		return http(ORDERS_API.dispatcherList(branchIdList, params, signal));
	};
	// ! effects
	useEffect(() => {
		const ctrl = new AbortController();

		fetchBranchList(ctrl.signal)
			.then(processResponse)
			.catch((error) => {
				handleError(error);
			});
	}, [refreshController.refreshId]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return <></>;
};
