import { FC, PropsWithChildren, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ECampaignFormFields, EPromotionTarget, ICampaignForm } from 'types/api/promotions';
import CustomDrawer from 'components/CustomDrawer';
import { PROMOTION_DEFAULT_VALUES, PROMOTION_TARGET_SPECIFIC_DEFAULTS } from '../../../config';
import { ICampaignCreateController } from '../../../hooks/useCampaignCreateController';
import { Button, Col, DrawerProps, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { SettingOutlined } from '@ant-design/icons';

export const DiscountAdvancedOptionsDrawer: FC<
	PropsWithChildren<DrawerProps & { controller: ICampaignCreateController }>
> = memo(({ children, controller, ...props }) => {
	const { t: tCampaigns } = useTranslation('campaigns');
	const [isAdvancedOpen, setAdvancedOpen] = useState(false);

	const [previousFormValues, setPreviousFormValues] = useState<ICampaignForm>();

	const {
		form,
		formData: { target = EPromotionTarget.ACQUISITION },
	} = controller;

	const handleAdvancedSettingsToggle = () => {
		setAdvancedOpen((prev) => !prev);
	};

	const handleClose = () => {
		// Reset form to previous state
		if (previousFormValues) form.setFieldsValue(previousFormValues);

		handleAdvancedSettingsToggle();
	};

	const handleResetToDefault = () => {
		if (!PROMOTION_TARGET_SPECIFIC_DEFAULTS[target]) return;

		form.setFieldsValue({
			[ECampaignFormFields.MINIMUM_ORDER_AMOUNT]:
				PROMOTION_DEFAULT_VALUES[ECampaignFormFields.MINIMUM_ORDER_AMOUNT],
			[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT]:
				PROMOTION_DEFAULT_VALUES[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT],
			[ECampaignFormFields.USER_USAGE_LIMIT]:
				PROMOTION_TARGET_SPECIFIC_DEFAULTS[target]?.[ECampaignFormFields.USER_USAGE_LIMIT],
			[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: PROMOTION_DEFAULT_VALUES[ECampaignFormFields.GLOBAL_USAGE_LIMIT],
		});
	};

	const handleSave = () => {
		handleAdvancedSettingsToggle();
	};

	// ! useEffect
	useEffect(() => {
		if (isAdvancedOpen) {
			// save previous form state
			setPreviousFormValues(form.getFieldsValue());
		} else {
			setPreviousFormValues(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdvancedOpen]);

	return (
		<>
			<FormItem>
				<Button
					size='large'
					icon={<SettingOutlined />}
					onClick={() => handleAdvancedSettingsToggle()}
				>
					{tCampaigns('create.form.discount_advanced_settings.toggle_open')}
				</Button>
			</FormItem>

			<CustomDrawer
				closable
				forceRender
				size='large'
				open={isAdvancedOpen}
				onClose={handleClose}
				title={tCampaigns('create.form.discount_advanced_settings.title')}
				placement='right'
				footer={
					<Row
						gutter={[16, 16]}
						wrap={false}
					>
						<Col flex={1}>
							<Button
								type='dashed'
								size='large'
								className='w-100'
								onClick={handleResetToDefault}
							>
								{tCampaigns('create.form.discount_advanced_settings.cta.reset')}
							</Button>
						</Col>
						<Col flex={2}>
							<Button
								type='primary'
								size='large'
								className='w-100'
								onClick={handleSave}
							>
								{tCampaigns('create.form.discount_advanced_settings.cta.save')}
							</Button>
						</Col>
					</Row>
				}
				{...props}
			>
				{children}
			</CustomDrawer>
		</>
	);
});
