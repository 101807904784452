import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { cleanup, fetchBranchInformation, setData } from 'store/slices/branch';
import { useGlobalConfigs, useHandleErrors, useVendor } from 'hooks';
import { throttle } from 'utils/throttle';
import { IBranch } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';

interface IBranchContextData {
	branchId: number;

	data: Nullable<IBranch>;
	error: Nullable<any>;
	isLoading: boolean;

	// ! handlers
	fetchData: TEmptyFunction;
	setData: (data: IBranch) => void;
}

const BranchContext = createContext<IBranchContextData>({} as IBranchContextData);

const useBranch = () => {
	return useContext(BranchContext);
};

interface IBranchProviderProps extends PropsWithChildren {
	branchId?: number;
}

const BranchProvider = ({ children, branchId: branchIdArg }: IBranchProviderProps) => {
	const { vendorId } = useVendor();
	const { branchId: branchIdParam } = useParams();
	const { http } = useGlobalConfigs();
	const dispatch = useDispatch();
	const { handleError } = useHandleErrors();

	// ! selectors
	const error = useSelector((state) => state.branch.error);
	const data = useSelector((state) => state.branch.data);
	const isLoading = useSelector((state) => state.branch.loading);

	// ! memos
	const branchId = useMemo(() => {
		const parsedBranchIdParam = branchIdParam ? parseInt(branchIdParam) : undefined;

		return branchIdArg ?? parsedBranchIdParam;
	}, [branchIdArg, branchIdParam]);

	// ! handlers
	const fetchData = throttle(() => {
		if (!vendorId || !branchId || isLoading) return;

		return dispatch(fetchBranchInformation({ vendorId: +vendorId, branchId: +branchId, http, handleError }));
	});

	const setDataHandle = (data: IBranch) => {
		dispatch(setData(data));
	};

	// ! useEffects
	useEffect(() => {
		fetchData();

		return () => {
			dispatch(cleanup());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchId]);

	// ! render
	if (!branchId) return null;

	const vendorData = {
		branchId: +branchId,
		data,
		error,
		isLoading,

		// * handlers
		fetchData,
		setData: setDataHandle,
	};

	return <BranchContext.Provider value={vendorData}>{children}</BranchContext.Provider>;
};

export { useBranch, BranchProvider };
